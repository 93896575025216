interface stateTaxSystem {
    stateCode: string,
    taxSystem: 'none' | 'flat' | 'progressive'
}

interface StateFlatTax {
    stateCode: string;
    taxRate: number;
}

interface ProgressiveStateTax {
    stateCode: string,
    marriedRate: boolean,
    minIncome: number,
    maxIncome: number,
    taxRatePercentage: number
}

const statesTaxSystems: stateTaxSystem[] = [
    { stateCode: "AL", taxSystem: "progressive" }, // Alabama
    { stateCode: "AK", taxSystem: "none" },       // Alaska
    { stateCode: "AZ", taxSystem: "flat" },       // Arizona
    { stateCode: "AR", taxSystem: "progressive" }, // Arkansas
    { stateCode: "CA", taxSystem: "progressive" }, // California
    { stateCode: "CO", taxSystem: "flat" },       // Colorado
    { stateCode: "CT", taxSystem: "progressive" }, // Connecticut
    { stateCode: "DE", taxSystem: "progressive" }, // Delaware
    { stateCode: "FL", taxSystem: "none" },       // Florida
    { stateCode: "GA", taxSystem: "progressive" }, // Georgia
    { stateCode: "HI", taxSystem: "progressive" }, // Hawaii
    { stateCode: "ID", taxSystem: "flat" },       // Idaho
    { stateCode: "IL", taxSystem: "flat" },       // Illinois
    { stateCode: "IN", taxSystem: "flat" },       // Indiana
    { stateCode: "IA", taxSystem: "flat" },       // Iowa
    { stateCode: "KS", taxSystem: "progressive" }, // Kansas
    { stateCode: "KY", taxSystem: "flat" },       // Kentucky
    { stateCode: "LA", taxSystem: "progressive" }, // Louisiana
    { stateCode: "ME", taxSystem: "progressive" }, // Maine
    { stateCode: "MD", taxSystem: "progressive" }, // Maryland
    { stateCode: "MA", taxSystem: "flat" },       // Massachusetts
    { stateCode: "MI", taxSystem: "flat" },       // Michigan
    { stateCode: "MN", taxSystem: "progressive" }, // Minnesota
    { stateCode: "MS", taxSystem: "progressive" }, // Mississippi (transitioning to flat by 2024)
    { stateCode: "MO", taxSystem: "progressive" }, // Missouri
    { stateCode: "MT", taxSystem: "progressive" }, // Montana
    { stateCode: "NE", taxSystem: "progressive" }, // Nebraska
    { stateCode: "NV", taxSystem: "none" },       // Nevada
    { stateCode: "NH", taxSystem: "none" },       // New Hampshire (limited to interest/dividends)
    { stateCode: "NJ", taxSystem: "progressive" }, // New Jersey
    { stateCode: "NM", taxSystem: "progressive" }, // New Mexico
    { stateCode: "NY", taxSystem: "progressive" }, // New York
    { stateCode: "NC", taxSystem: "flat" },       // North Carolina
    { stateCode: "ND", taxSystem: "progressive" }, // North Dakota
    { stateCode: "OH", taxSystem: "progressive" }, // Ohio
    { stateCode: "OK", taxSystem: "progressive" }, // Oklahoma
    { stateCode: "OR", taxSystem: "progressive" }, // Oregon
    { stateCode: "PA", taxSystem: "flat" },       // Pennsylvania
    { stateCode: "RI", taxSystem: "progressive" }, // Rhode Island
    { stateCode: "SC", taxSystem: "progressive" }, // South Carolina
    { stateCode: "SD", taxSystem: "none" },       // South Dakota
    { stateCode: "TN", taxSystem: "none" },       // Tennessee (fully phased out by 2021)
    { stateCode: "TX", taxSystem: "none" },       // Texas
    { stateCode: "UT", taxSystem: "flat" },       // Utah
    { stateCode: "VT", taxSystem: "progressive" }, // Vermont
    { stateCode: "VA", taxSystem: "progressive" }, // Virginia
    { stateCode: "WA", taxSystem: "none" },       // Washington (capital gains tax only)
    { stateCode: "WV", taxSystem: "progressive" }, // West Virginia
    { stateCode: "WI", taxSystem: "progressive" }, // Wisconsin
    { stateCode: "WY", taxSystem: "none" }        // Wyoming
];

const flatTaxStates: StateFlatTax[] = [
    { stateCode: "AZ", taxRate: 2.5 },  // Arizona
    { stateCode: "CO", taxRate: 4.4 },  // Colorado
    { stateCode: "IL", taxRate: 4.95 }, // Illinois
    { stateCode: "IN", taxRate: 3.15 }, // Indiana
    { stateCode: "KY", taxRate: 4.5 },  // Kentucky
    { stateCode: "MI", taxRate: 4.25 }, // Michigan
    { stateCode: "NC", taxRate: 4.75 }, // North Carolina
    { stateCode: "UT", taxRate: 4.85 }, // Utah
    { stateCode: "PA", taxRate: 3.07 }, // Pennsylvania
    { stateCode: "MA", taxRate: 5.0 },  // Massachusetts
    { stateCode: "IA", taxRate: 3.9 },  // Iowa (from 2023)
    { stateCode: "ID", taxRate: 5.8 },  // Idaho
];

const progressiveTaxStates: ProgressiveStateTax[] = [
    // Alabama
    { stateCode: "AL", marriedRate: false, minIncome: 0, maxIncome: 500, taxRatePercentage: 2 },
    { stateCode: "AL", marriedRate: false, minIncome: 501, maxIncome: 3000, taxRatePercentage: 4 },
    { stateCode: "AL", marriedRate: false, minIncome: 3001, maxIncome: Infinity, taxRatePercentage: 5 },
    { stateCode: "AL", marriedRate: true, minIncome: 0, maxIncome: 1000, taxRatePercentage: 2 },
    { stateCode: "AL", marriedRate: true, minIncome: 1001, maxIncome: 6000, taxRatePercentage: 4 },
    { stateCode: "AL", marriedRate: true, minIncome: 6001, maxIncome: Infinity, taxRatePercentage: 5 },

    { stateCode: "AR", marriedRate: false, minIncome: 0, maxIncome: 4500, taxRatePercentage: 2 },
    { stateCode: "AR", marriedRate: false, minIncome: 4501, maxIncome: 8900, taxRatePercentage: 4 },
    { stateCode: "AR", marriedRate: false, minIncome: 8901, maxIncome: Infinity, taxRatePercentage: 5.5 },
    { stateCode: "AR", marriedRate: true, minIncome: 0, maxIncome: 4500, taxRatePercentage: 2 },
    { stateCode: "AR", marriedRate: true, minIncome: 4501, maxIncome: 17800, taxRatePercentage: 4 },
    { stateCode: "AR", marriedRate: true, minIncome: 17801, maxIncome: Infinity, taxRatePercentage: 5.5 },

    // California
    { stateCode: "CA", marriedRate: false, minIncome: 0, maxIncome: 10099, taxRatePercentage: 1 },
    { stateCode: "CA", marriedRate: false, minIncome: 10100, maxIncome: 23942, taxRatePercentage: 2 },
    { stateCode: "CA", marriedRate: false, minIncome: 23943, maxIncome: 37788, taxRatePercentage: 4 },
    { stateCode: "CA", marriedRate: false, minIncome: 37789, maxIncome: 52455, taxRatePercentage: 6 },
    { stateCode: "CA", marriedRate: false, minIncome: 52456, maxIncome: 66295, taxRatePercentage: 8 },
    { stateCode: "CA", marriedRate: false, minIncome: 66296, maxIncome: 338639, taxRatePercentage: 9.3 },
    { stateCode: "CA", marriedRate: false, minIncome: 338640, maxIncome: 406364, taxRatePercentage: 10.3 },
    { stateCode: "CA", marriedRate: false, minIncome: 406365, maxIncome: 677275, taxRatePercentage: 11.3 },
    { stateCode: "CA", marriedRate: false, minIncome: 677276, maxIncome: 1000000, taxRatePercentage: 12.3 },
    { stateCode: "CA", marriedRate: false, minIncome: 1000001, maxIncome: Infinity, taxRatePercentage: 13.3 },
    { stateCode: "CA", marriedRate: true, minIncome: 0, maxIncome: 20198, taxRatePercentage: 1 },
    { stateCode: "CA", marriedRate: true, minIncome: 20199, maxIncome: 47884, taxRatePercentage: 2 },
    { stateCode: "CA", marriedRate: true, minIncome: 47885, maxIncome: 75576, taxRatePercentage: 4 },
    { stateCode: "CA", marriedRate: true, minIncome: 75577, maxIncome: 104910, taxRatePercentage: 6 },
    { stateCode: "CA", marriedRate: true, minIncome: 104911, maxIncome: 132590, taxRatePercentage: 8 },
    { stateCode: "CA", marriedRate: true, minIncome: 132591, maxIncome: 677278, taxRatePercentage: 9.3 },
    { stateCode: "CA", marriedRate: true, minIncome: 677279, maxIncome: 812728, taxRatePercentage: 10.3 },
    { stateCode: "CA", marriedRate: true, minIncome: 812729, maxIncome: 1354550, taxRatePercentage: 11.3 },
    { stateCode: "CA", marriedRate: true, minIncome: 1354551, maxIncome: 2000000, taxRatePercentage: 12.3 },
    { stateCode: "CA", marriedRate: true, minIncome: 2000001, maxIncome: Infinity, taxRatePercentage: 13.3 },


    // Connecticut
    { stateCode: "CT", marriedRate: false, minIncome: 0, maxIncome: 10000, taxRatePercentage: 3 },
    { stateCode: "CT", marriedRate: false, minIncome: 10001, maxIncome: 50000, taxRatePercentage: 5 },
    { stateCode: "CT", marriedRate: false, minIncome: 50001, maxIncome: 100000, taxRatePercentage: 5.5 },
    { stateCode: "CT", marriedRate: false, minIncome: 100001, maxIncome: 200000, taxRatePercentage: 6 },
    { stateCode: "CT", marriedRate: false, minIncome: 200001, maxIncome: 500000, taxRatePercentage: 6.5 },
    { stateCode: "CT", marriedRate: false, minIncome: 500001, maxIncome: Infinity, taxRatePercentage: 6.99 },
    { stateCode: "CT", marriedRate: true, minIncome: 0, maxIncome: 20000, taxRatePercentage: 3 },
    { stateCode: "CT", marriedRate: true, minIncome: 20001, maxIncome: 100000, taxRatePercentage: 5 },
    { stateCode: "CT", marriedRate: true, minIncome: 100001, maxIncome: 200000, taxRatePercentage: 5.5 },
    { stateCode: "CT", marriedRate: true, minIncome: 200001, maxIncome: 400000, taxRatePercentage: 6 },
    { stateCode: "CT", marriedRate: true, minIncome: 400001, maxIncome: 1000000, taxRatePercentage: 6.5 },
    { stateCode: "CT", marriedRate: true, minIncome: 1000001, maxIncome: Infinity, taxRatePercentage: 6.99 },

    // Delaware
    { stateCode: "DE", marriedRate: false, minIncome: 0, maxIncome: 2000, taxRatePercentage: 2.2 },
    { stateCode: "DE", marriedRate: false, minIncome: 2001, maxIncome: 5000, taxRatePercentage: 3.9 },
    { stateCode: "DE", marriedRate: false, minIncome: 5001, maxIncome: 10000, taxRatePercentage: 4.8 },
    { stateCode: "DE", marriedRate: false, minIncome: 10001, maxIncome: 20000, taxRatePercentage: 5.2 },
    { stateCode: "DE", marriedRate: false, minIncome: 20001, maxIncome: 60000, taxRatePercentage: 5.55 },
    { stateCode: "DE", marriedRate: false, minIncome: 60001, maxIncome: Infinity, taxRatePercentage: 6.6 },
    { stateCode: "DE", marriedRate: true, minIncome: 0, maxIncome: 2000, taxRatePercentage: 2.2 },
    { stateCode: "DE", marriedRate: true, minIncome: 2001, maxIncome: 5000, taxRatePercentage: 3.9 },
    { stateCode: "DE", marriedRate: true, minIncome: 5001, maxIncome: 10000, taxRatePercentage: 4.8 },
    { stateCode: "DE", marriedRate: true, minIncome: 10001, maxIncome: 20000, taxRatePercentage: 5.2 },
    { stateCode: "DE", marriedRate: true, minIncome: 20001, maxIncome: 60000, taxRatePercentage: 5.55 },
    { stateCode: "DE", marriedRate: true, minIncome: 60001, maxIncome: Infinity, taxRatePercentage: 6.6 },

    // Georgia
    { stateCode: "GA", marriedRate: false, minIncome: 0, maxIncome: 750, taxRatePercentage: 1 },
    { stateCode: "GA", marriedRate: false, minIncome: 751, maxIncome: 2250, taxRatePercentage: 2 },
    { stateCode: "GA", marriedRate: false, minIncome: 2251, maxIncome: 3750, taxRatePercentage: 3 },
    { stateCode: "GA", marriedRate: false, minIncome: 3751, maxIncome: 5250, taxRatePercentage: 4 },
    { stateCode: "GA", marriedRate: false, minIncome: 5251, maxIncome: 7000, taxRatePercentage: 5 },
    { stateCode: "GA", marriedRate: false, minIncome: 7001, maxIncome: Infinity, taxRatePercentage: 5.75 },
    { stateCode: "GA", marriedRate: true, minIncome: 0, maxIncome: 1000, taxRatePercentage: 1 },
    { stateCode: "GA", marriedRate: true, minIncome: 1001, maxIncome: 3000, taxRatePercentage: 2 },
    { stateCode: "GA", marriedRate: true, minIncome: 3001, maxIncome: 5000, taxRatePercentage: 3 },
    { stateCode: "GA", marriedRate: true, minIncome: 5001, maxIncome: 7000, taxRatePercentage: 4 },
    { stateCode: "GA", marriedRate: true, minIncome: 7001, maxIncome: 10000, taxRatePercentage: 5 },
    { stateCode: "GA", marriedRate: true, minIncome: 10001, maxIncome: Infinity, taxRatePercentage: 5.75 },

    // Hawaii
    { stateCode: "HI", marriedRate: false, minIncome: 0, maxIncome: 2400, taxRatePercentage: 1.4 },
    { stateCode: "HI", marriedRate: false, minIncome: 2401, maxIncome: 4800, taxRatePercentage: 3.2 },
    { stateCode: "HI", marriedRate: false, minIncome: 4801, maxIncome: 9600, taxRatePercentage: 5.5 },
    { stateCode: "HI", marriedRate: false, minIncome: 9601, maxIncome: 14400, taxRatePercentage: 6.4 },
    { stateCode: "HI", marriedRate: false, minIncome: 14401, maxIncome: 19200, taxRatePercentage: 6.8 },
    { stateCode: "HI", marriedRate: false, minIncome: 19201, maxIncome: 24000, taxRatePercentage: 7.2 },
    { stateCode: "HI", marriedRate: false, minIncome: 24001, maxIncome: 36000, taxRatePercentage: 7.6 },
    { stateCode: "HI", marriedRate: false, minIncome: 36001, maxIncome: 48000, taxRatePercentage: 7.9 },
    { stateCode: "HI", marriedRate: false, minIncome: 48001, maxIncome: 150000, taxRatePercentage: 8.25 },
    { stateCode: "HI", marriedRate: false, minIncome: 150001, maxIncome: 175000, taxRatePercentage: 9 },
    { stateCode: "HI", marriedRate: false, minIncome: 175001, maxIncome: 200000, taxRatePercentage: 10 },
    { stateCode: "HI", marriedRate: false, minIncome: 200001, maxIncome: Infinity, taxRatePercentage: 11 },
    { stateCode: "HI", marriedRate: true, minIncome: 0, maxIncome: 4800, taxRatePercentage: 1.4 },
    { stateCode: "HI", marriedRate: true, minIncome: 4801, maxIncome: 9600, taxRatePercentage: 3.2 },
    { stateCode: "HI", marriedRate: true, minIncome: 9601, maxIncome: 19200, taxRatePercentage: 5.5 },
    { stateCode: "HI", marriedRate: true, minIncome: 19201, maxIncome: 28800, taxRatePercentage: 6.4 },
    { stateCode: "HI", marriedRate: true, minIncome: 28801, maxIncome: 38400, taxRatePercentage: 6.8 },
    { stateCode: "HI", marriedRate: true, minIncome: 38401, maxIncome: 48000, taxRatePercentage: 7.2 },
    { stateCode: "HI", marriedRate: true, minIncome: 48001, maxIncome: 72000, taxRatePercentage: 7.6 },
    { stateCode: "HI", marriedRate: true, minIncome: 72001, maxIncome: 96000, taxRatePercentage: 7.9 },
    { stateCode: "HI", marriedRate: true, minIncome: 96001, maxIncome: 300000, taxRatePercentage: 8.25 },
    { stateCode: "HI", marriedRate: true, minIncome: 300001, maxIncome: 350000, taxRatePercentage: 9 },
    { stateCode: "HI", marriedRate: true, minIncome: 350001, maxIncome: 400000, taxRatePercentage: 10 },
    { stateCode: "HI", marriedRate: true, minIncome: 400001, maxIncome: Infinity, taxRatePercentage: 11 },


    // Idaho
    { stateCode: "ID", marriedRate: false, minIncome: 0, maxIncome: 1645, taxRatePercentage: 1 },
    { stateCode: "ID", marriedRate: false, minIncome: 1646, maxIncome: 3290, taxRatePercentage: 3 },
    { stateCode: "ID", marriedRate: false, minIncome: 3291, maxIncome: 4943, taxRatePercentage: 4.5 },
    { stateCode: "ID", marriedRate: false, minIncome: 4944, maxIncome: Infinity, taxRatePercentage: 5.8 },
    { stateCode: "ID", marriedRate: true, minIncome: 0, maxIncome: 3290, taxRatePercentage: 1 },
    { stateCode: "ID", marriedRate: true, minIncome: 3291, maxIncome: 6580, taxRatePercentage: 3 },
    { stateCode: "ID", marriedRate: true, minIncome: 6581, maxIncome: 9887, taxRatePercentage: 4.5 },
    { stateCode: "ID", marriedRate: true, minIncome: 9888, maxIncome: Infinity, taxRatePercentage: 5.8 },

    // Illinois
    { stateCode: "IL", marriedRate: false, minIncome: 0, maxIncome: Infinity, taxRatePercentage: 4.95 },
    { stateCode: "IL", marriedRate: true, minIncome: 0, maxIncome: Infinity, taxRatePercentage: 4.95 },

    // Example: Iowa
    { stateCode: "IA", marriedRate: false, minIncome: 0, maxIncome: 1643, taxRatePercentage: 0.33 },
    { stateCode: "IA", marriedRate: false, minIncome: 1644, maxIncome: 3277, taxRatePercentage: 0.67 },
    { stateCode: "IA", marriedRate: false, minIncome: 3278, maxIncome: 6553, taxRatePercentage: 2.25 },
    { stateCode: "IA", marriedRate: false, minIncome: 6554, maxIncome: 14701, taxRatePercentage: 4.14 },
    { stateCode: "IA", marriedRate: false, minIncome: 14702, maxIncome: 24503, taxRatePercentage: 5.63 },
    { stateCode: "IA", marriedRate: false, minIncome: 24504, maxIncome: 32704, taxRatePercentage: 5.96 },
    { stateCode: "IA", marriedRate: false, minIncome: 32705, maxIncome: 49106, taxRatePercentage: 6.25 },
    { stateCode: "IA", marriedRate: false, minIncome: 49107, maxIncome: Infinity, taxRatePercentage: 6.84 },
    { stateCode: "IA", marriedRate: true, minIncome: 0, maxIncome: 1632, taxRatePercentage: 0.33 },
    { stateCode: "IA", marriedRate: true, minIncome: 1633, maxIncome: 3264, taxRatePercentage: 0.67 },
    { stateCode: "IA", marriedRate: true, minIncome: 3265, maxIncome: 6531, taxRatePercentage: 2.25 },
    { stateCode: "IA", marriedRate: true, minIncome: 6532, maxIncome: 14678, taxRatePercentage: 4.14 },
    { stateCode: "IA", marriedRate: true, minIncome: 14679, maxIncome: 24464, taxRatePercentage: 5.63 },
    { stateCode: "IA", marriedRate: true, minIncome: 24465, maxIncome: 32618, taxRatePercentage: 5.96 },
    { stateCode: "IA", marriedRate: true, minIncome: 32619, maxIncome: 49050, taxRatePercentage: 6.25 },
    { stateCode: "IA", marriedRate: true, minIncome: 49051, maxIncome: Infinity, taxRatePercentage: 6.84 },

    // Kansas
    { stateCode: "KS", marriedRate: false, minIncome: 0, maxIncome: 2500, taxRatePercentage: 3.1 },
    { stateCode: "KS", marriedRate: false, minIncome: 2501, maxIncome: 15000, taxRatePercentage: 5.25 },
    { stateCode: "KS", marriedRate: false, minIncome: 15001, maxIncome: Infinity, taxRatePercentage: 5.7 },
    { stateCode: "KS", marriedRate: true, minIncome: 0, maxIncome: 5000, taxRatePercentage: 3.1 },
    { stateCode: "KS", marriedRate: true, minIncome: 5001, maxIncome: 30000, taxRatePercentage: 5.25 },
    { stateCode: "KS", marriedRate: true, minIncome: 30001, maxIncome: Infinity, taxRatePercentage: 5.7 },

    // Kentucky
    { stateCode: "KY", marriedRate: false, minIncome: 0, maxIncome: 3000, taxRatePercentage: 2 },
    { stateCode: "KY", marriedRate: false, minIncome: 3001, maxIncome: 4000, taxRatePercentage: 3 },
    { stateCode: "KY", marriedRate: false, minIncome: 4001, maxIncome: 5000, taxRatePercentage: 4 },
    { stateCode: "KY", marriedRate: false, minIncome: 5001, maxIncome: 8000, taxRatePercentage: 5 },
    { stateCode: "KY", marriedRate: false, minIncome: 8001, maxIncome: Infinity, taxRatePercentage: 5 },
    { stateCode: "KY", marriedRate: true, minIncome: 0, maxIncome: 3000, taxRatePercentage: 2 },
    { stateCode: "KY", marriedRate: true, minIncome: 3001, maxIncome: 4000, taxRatePercentage: 3 },
    { stateCode: "KY", marriedRate: true, minIncome: 4001, maxIncome: 5000, taxRatePercentage: 4 },
    { stateCode: "KY", marriedRate: true, minIncome: 5001, maxIncome: 8000, taxRatePercentage: 5 },
    { stateCode: "KY", marriedRate: true, minIncome: 8001, maxIncome: Infinity, taxRatePercentage: 5 },

    // Louisiana
    { stateCode: "LA", marriedRate: false, minIncome: 0, maxIncome: 12500, taxRatePercentage: 2 },
    { stateCode: "LA", marriedRate: false, minIncome: 12501, maxIncome: 50000, taxRatePercentage: 4 },
    { stateCode: "LA", marriedRate: false, minIncome: 50001, maxIncome: Infinity, taxRatePercentage: 6 },
    { stateCode: "LA", marriedRate: true, minIncome: 0, maxIncome: 25000, taxRatePercentage: 2 },
    { stateCode: "LA", marriedRate: true, minIncome: 25001, maxIncome: 100000, taxRatePercentage: 4 },
    { stateCode: "LA", marriedRate: true, minIncome: 100001, maxIncome: Infinity, taxRatePercentage: 6 },



    // Maine
    { stateCode: "ME", marriedRate: false, minIncome: 0, maxIncome: 23000, taxRatePercentage: 5.8 },
    { stateCode: "ME", marriedRate: false, minIncome: 23001, maxIncome: 54450, taxRatePercentage: 6.75 },
    { stateCode: "ME", marriedRate: false, minIncome: 54451, maxIncome: Infinity, taxRatePercentage: 7.15 },
    { stateCode: "ME", marriedRate: true, minIncome: 0, maxIncome: 46000, taxRatePercentage: 5.8 },
    { stateCode: "ME", marriedRate: true, minIncome: 46001, maxIncome: 108900, taxRatePercentage: 6.75 },
    { stateCode: "ME", marriedRate: true, minIncome: 108901, maxIncome: Infinity, taxRatePercentage: 7.15 },

    // Maryland
    { stateCode: "MD", marriedRate: false, minIncome: 0, maxIncome: 1000, taxRatePercentage: 2 },
    { stateCode: "MD", marriedRate: false, minIncome: 1001, maxIncome: 2000, taxRatePercentage: 3 },
    { stateCode: "MD", marriedRate: false, minIncome: 2001, maxIncome: 3000, taxRatePercentage: 4 },
    { stateCode: "MD", marriedRate: false, minIncome: 3001, maxIncome: 100000, taxRatePercentage: 4.75 },
    { stateCode: "MD", marriedRate: false, minIncome: 100001, maxIncome: 125000, taxRatePercentage: 5 },
    { stateCode: "MD", marriedRate: false, minIncome: 125001, maxIncome: 150000, taxRatePercentage: 5.25 },
    { stateCode: "MD", marriedRate: false, minIncome: 150001, maxIncome: 250000, taxRatePercentage: 5.5 },
    { stateCode: "MD", marriedRate: false, minIncome: 250001, maxIncome: Infinity, taxRatePercentage: 5.75 },
    { stateCode: "MD", marriedRate: true, minIncome: 0, maxIncome: 1000, taxRatePercentage: 2 },
    { stateCode: "MD", marriedRate: true, minIncome: 1001, maxIncome: 2000, taxRatePercentage: 3 },
    { stateCode: "MD", marriedRate: true, minIncome: 2001, maxIncome: 3000, taxRatePercentage: 4 },
    { stateCode: "MD", marriedRate: true, minIncome: 3001, maxIncome: 100000, taxRatePercentage: 4.75 },
    { stateCode: "MD", marriedRate: true, minIncome: 100001, maxIncome: 125000, taxRatePercentage: 5 },
    { stateCode: "MD", marriedRate: true, minIncome: 125001, maxIncome: 150000, taxRatePercentage: 5.25 },
    { stateCode: "MD", marriedRate: true, minIncome: 150001, maxIncome: 250000, taxRatePercentage: 5.5 },
    { stateCode: "MD", marriedRate: true, minIncome: 250001, maxIncome: Infinity, taxRatePercentage: 5.75 },

    // Missouri
    { stateCode: "MO", marriedRate: false, minIncome: 0, maxIncome: 108, taxRatePercentage: 0 },
    { stateCode: "MO", marriedRate: false, minIncome: 109, maxIncome: 1088, taxRatePercentage: 2 },
    { stateCode: "MO", marriedRate: false, minIncome: 1089, maxIncome: 2175, taxRatePercentage: 3 },
    { stateCode: "MO", marriedRate: false, minIncome: 2176, maxIncome: 3263, taxRatePercentage: 5 },
    { stateCode: "MO", marriedRate: false, minIncome: 3264, maxIncome: Infinity, taxRatePercentage: 5.4 },
    { stateCode: "MO", marriedRate: true, minIncome: 0, maxIncome: 108, taxRatePercentage: 0 },
    { stateCode: "MO", marriedRate: true, minIncome: 109, maxIncome: 1088, taxRatePercentage: 2 },
    { stateCode: "MO", marriedRate: true, minIncome: 1089, maxIncome: 2175, taxRatePercentage: 3 },
    { stateCode: "MO", marriedRate: true, minIncome: 2176, maxIncome: 3263, taxRatePercentage: 5 },
    { stateCode: "MO", marriedRate: true, minIncome: 3264, maxIncome: Infinity, taxRatePercentage: 5.4 },

    // Montana
    { stateCode: "MT", marriedRate: false, minIncome: 0, maxIncome: 3400, taxRatePercentage: 1 },
    { stateCode: "MT", marriedRate: false, minIncome: 3401, maxIncome: 7800, taxRatePercentage: 3 },
    { stateCode: "MT", marriedRate: false, minIncome: 7801, maxIncome: 11900, taxRatePercentage: 4 },
    { stateCode: "MT", marriedRate: false, minIncome: 11901, maxIncome: 15700, taxRatePercentage: 5 },
    { stateCode: "MT", marriedRate: false, minIncome: 15701, maxIncome: 20500, taxRatePercentage: 6 },
    { stateCode: "MT", marriedRate: false, minIncome: 20501, maxIncome: Infinity, taxRatePercentage: 6.75 },
    { stateCode: "MT", marriedRate: true, minIncome: 0, maxIncome: 3400, taxRatePercentage: 1 },
    { stateCode: "MT", marriedRate: true, minIncome: 3401, maxIncome: 7800, taxRatePercentage: 3 },
    { stateCode: "MT", marriedRate: true, minIncome: 7801, maxIncome: 11900, taxRatePercentage: 4 },
    { stateCode: "MT", marriedRate: true, minIncome: 11901, maxIncome: 15700, taxRatePercentage: 5 },
    { stateCode: "MT", marriedRate: true, minIncome: 15701, maxIncome: 20500, taxRatePercentage: 6 },
    { stateCode: "MT", marriedRate: true, minIncome: 20501, maxIncome: Infinity, taxRatePercentage: 6.75 },

    // Minnesota
    { stateCode: "MN", marriedRate: false, minIncome: 0, maxIncome: 29060, taxRatePercentage: 5.35 },
    { stateCode: "MN", marriedRate: false, minIncome: 29061, maxIncome: 102030, taxRatePercentage: 6.8 },
    { stateCode: "MN", marriedRate: false, minIncome: 102031, maxIncome: 186700, taxRatePercentage: 7.85 },
    { stateCode: "MN", marriedRate: false, minIncome: 186701, maxIncome: Infinity, taxRatePercentage: 9.85 },
    { stateCode: "MN", marriedRate: true, minIncome: 0, maxIncome: 41620, taxRatePercentage: 5.35 },
    { stateCode: "MN", marriedRate: true, minIncome: 41621, maxIncome: 163060, taxRatePercentage: 6.8 },
    { stateCode: "MN", marriedRate: true, minIncome: 163061, maxIncome: 279160, taxRatePercentage: 7.85 },
    { stateCode: "MN", marriedRate: true, minIncome: 279161, maxIncome: Infinity, taxRatePercentage: 9.85 },

    // Nebraska
    { stateCode: "NE", marriedRate: false, minIncome: 0, maxIncome: 3370, taxRatePercentage: 2.46 },
    { stateCode: "NE", marriedRate: false, minIncome: 3371, maxIncome: 20200, taxRatePercentage: 3.51 },
    { stateCode: "NE", marriedRate: false, minIncome: 20201, maxIncome: 32330, taxRatePercentage: 5.01 },
    { stateCode: "NE", marriedRate: false, minIncome: 32331, maxIncome: Infinity, taxRatePercentage: 6.84 },
    { stateCode: "NE", marriedRate: true, minIncome: 0, maxIncome: 6740, taxRatePercentage: 2.46 },
    { stateCode: "NE", marriedRate: true, minIncome: 6741, maxIncome: 40400, taxRatePercentage: 3.51 },
    { stateCode: "NE", marriedRate: true, minIncome: 40401, maxIncome: 64660, taxRatePercentage: 5.01 },
    { stateCode: "NE", marriedRate: true, minIncome: 64661, maxIncome: Infinity, taxRatePercentage: 6.84 },

    // NEW JERSEY
    { stateCode: "NJ", marriedRate: false, minIncome: 0, maxIncome: 20000, taxRatePercentage: 1.4 },
    { stateCode: "NJ", marriedRate: false, minIncome: 20001, maxIncome: 50000, taxRatePercentage: 1.75 },
    { stateCode: "NJ", marriedRate: false, minIncome: 50001, maxIncome: 75000, taxRatePercentage: 2.45 },
    { stateCode: "NJ", marriedRate: false, minIncome: 75001, maxIncome: 500000, taxRatePercentage: 3.5 },
    { stateCode: "NJ", marriedRate: false, minIncome: 500001, maxIncome: 5000000, taxRatePercentage: 8.97 },
    { stateCode: "NJ", marriedRate: false, minIncome: 5000001, maxIncome: Infinity, taxRatePercentage: 10.75 },
    { stateCode: "NJ", marriedRate: true, minIncome: 0, maxIncome: 20000, taxRatePercentage: 1.4 },
    { stateCode: "NJ", marriedRate: true, minIncome: 20001, maxIncome: 50000, taxRatePercentage: 1.75 },
    { stateCode: "NJ", marriedRate: true, minIncome: 50001, maxIncome: 75000, taxRatePercentage: 2.45 },
    { stateCode: "NJ", marriedRate: true, minIncome: 75001, maxIncome: 500000, taxRatePercentage: 3.5 },
    { stateCode: "NJ", marriedRate: true, minIncome: 500001, maxIncome: 5000000, taxRatePercentage: 8.97 },
    { stateCode: "NJ", marriedRate: true, minIncome: 5000001, maxIncome: Infinity, taxRatePercentage: 10.75 },


    // New York
    { stateCode: "NY", marriedRate: false, minIncome: 0, maxIncome: 8500, taxRatePercentage: 4 },
    { stateCode: "NY", marriedRate: false, minIncome: 8501, maxIncome: 11700, taxRatePercentage: 4.5 },
    { stateCode: "NY", marriedRate: false, minIncome: 11701, maxIncome: 13900, taxRatePercentage: 5.25 },
    { stateCode: "NY", marriedRate: false, minIncome: 13901, maxIncome: 21400, taxRatePercentage: 5.9 },
    { stateCode: "NY", marriedRate: false, minIncome: 21401, maxIncome: 80650, taxRatePercentage: 6.33 },
    { stateCode: "NY", marriedRate: false, minIncome: 80651, maxIncome: 215400, taxRatePercentage: 6.85 },
    { stateCode: "NY", marriedRate: false, minIncome: 215401, maxIncome: 1077550, taxRatePercentage: 9.65 },
    { stateCode: "NY", marriedRate: false, minIncome: 1077551, maxIncome: 5000000, taxRatePercentage: 10.3 },
    { stateCode: "NY", marriedRate: false, minIncome: 5000001, maxIncome: Infinity, taxRatePercentage: 10.9 },


    { stateCode: "ND", marriedRate: false, minIncome: 0, maxIncome: 40625, taxRatePercentage: 1.1 },
    { stateCode: "ND", marriedRate: false, minIncome: 40626, maxIncome: 98000, taxRatePercentage: 2.04 },
    { stateCode: "ND", marriedRate: false, minIncome: 98001, maxIncome: 203350, taxRatePercentage: 2.27 },
    { stateCode: "ND", marriedRate: false, minIncome: 203351, maxIncome: 444950, taxRatePercentage: 2.64 },
    { stateCode: "ND", marriedRate: false, minIncome: 444951, maxIncome: Infinity, taxRatePercentage: 2.9 },
    { stateCode: "ND", marriedRate: true, minIncome: 0, maxIncome: 67700, taxRatePercentage: 1.1 },
    { stateCode: "ND", marriedRate: true, minIncome: 67701, maxIncome: 163000, taxRatePercentage: 2.04 },
    { stateCode: "ND", marriedRate: true, minIncome: 163001, maxIncome: 247450, taxRatePercentage: 2.27 },
    { stateCode: "ND", marriedRate: true, minIncome: 247451, maxIncome: 444950, taxRatePercentage: 2.64 },
    { stateCode: "ND", marriedRate: true, minIncome: 444951, maxIncome: Infinity, taxRatePercentage: 2.9 },

    // New Mexico
    { stateCode: "NM", marriedRate: false, minIncome: 0, maxIncome: 5500, taxRatePercentage: 1.7 },
    { stateCode: "NM", marriedRate: false, minIncome: 5501, maxIncome: 11000, taxRatePercentage: 3.2 },
    { stateCode: "NM", marriedRate: false, minIncome: 11001, maxIncome: 16000, taxRatePercentage: 4.7 },
    { stateCode: "NM", marriedRate: false, minIncome: 16001, maxIncome: 210000, taxRatePercentage: 4.9 },
    { stateCode: "NM", marriedRate: false, minIncome: 210001, maxIncome: Infinity, taxRatePercentage: 5.9 },
    { stateCode: "NM", marriedRate: true, minIncome: 0, maxIncome: 8000, taxRatePercentage: 1.7 },
    { stateCode: "NM", marriedRate: true, minIncome: 8001, maxIncome: 16000, taxRatePercentage: 3.2 },
    { stateCode: "NM", marriedRate: true, minIncome: 16001, maxIncome: 24000, taxRatePercentage: 4.7 },
    { stateCode: "NM", marriedRate: true, minIncome: 24001, maxIncome: 315000, taxRatePercentage: 4.9 },
    { stateCode: "NM", marriedRate: true, minIncome: 315001, maxIncome: Infinity, taxRatePercentage: 5.9 },

    // Oregon
    { stateCode: "OR", marriedRate: false, minIncome: 0, maxIncome: 3650, taxRatePercentage: 4.75 },
    { stateCode: "OR", marriedRate: false, minIncome: 3651, maxIncome: 9200, taxRatePercentage: 6.75 },
    { stateCode: "OR", marriedRate: false, minIncome: 9201, maxIncome: 125000, taxRatePercentage: 8.75 },
    { stateCode: "OR", marriedRate: false, minIncome: 125001, maxIncome: Infinity, taxRatePercentage: 9.9 },
    { stateCode: "OR", marriedRate: true, minIncome: 0, maxIncome: 7300, taxRatePercentage: 4.75 },
    { stateCode: "OR", marriedRate: true, minIncome: 7301, maxIncome: 18400, taxRatePercentage: 6.75 },
    { stateCode: "OR", marriedRate: true, minIncome: 18401, maxIncome: 250000, taxRatePercentage: 8.75 },
    { stateCode: "OR", marriedRate: true, minIncome: 250001, maxIncome: Infinity, taxRatePercentage: 9.9 },

    // Ohio
    { stateCode: "OH", marriedRate: false, minIncome: 0, maxIncome: 25000, taxRatePercentage: 2.85 },
    { stateCode: "OH", marriedRate: false, minIncome: 25001, maxIncome: 50000, taxRatePercentage: 3.32 },
    { stateCode: "OH", marriedRate: false, minIncome: 50001, maxIncome: 100000, taxRatePercentage: 3.68 },
    { stateCode: "OH", marriedRate: false, minIncome: 100001, maxIncome: Infinity, taxRatePercentage: 3.99 },
    { stateCode: "OH", marriedRate: true, minIncome: 0, maxIncome: 25000, taxRatePercentage: 2.85 },
    { stateCode: "OH", marriedRate: true, minIncome: 25001, maxIncome: 50000, taxRatePercentage: 3.32 },
    { stateCode: "OH", marriedRate: true, minIncome: 50001, maxIncome: 100000, taxRatePercentage: 3.68 },
    { stateCode: "OH", marriedRate: true, minIncome: 100001, maxIncome: Infinity, taxRatePercentage: 3.99 },

    // Oklahoma
    { stateCode: "OK", marriedRate: false, minIncome: 0, maxIncome: 1000, taxRatePercentage: 0.5 },
    { stateCode: "OK", marriedRate: false, minIncome: 1001, maxIncome: 2500, taxRatePercentage: 1 },
    { stateCode: "OK", marriedRate: false, minIncome: 2501, maxIncome: 3750, taxRatePercentage: 2 },
    { stateCode: "OK", marriedRate: false, minIncome: 3751, maxIncome: 4900, taxRatePercentage: 3 },
    { stateCode: "OK", marriedRate: false, minIncome: 4901, maxIncome: Infinity, taxRatePercentage: 4.75 },
    { stateCode: "OK", marriedRate: true, minIncome: 0, maxIncome: 2000, taxRatePercentage: 0.5 },
    { stateCode: "OK", marriedRate: true, minIncome: 2001, maxIncome: 5000, taxRatePercentage: 1 },
    { stateCode: "OK", marriedRate: true, minIncome: 5001, maxIncome: 7500, taxRatePercentage: 2 },
    { stateCode: "OK", marriedRate: true, minIncome: 7501, maxIncome: 9800, taxRatePercentage: 3 },
    { stateCode: "OK", marriedRate: true, minIncome: 9801, maxIncome: Infinity, taxRatePercentage: 4.75 },

    // Rhode Island
    { stateCode: "RI", marriedRate: false, minIncome: 0, maxIncome: 68200, taxRatePercentage: 3.75 },
    { stateCode: "RI", marriedRate: false, minIncome: 68201, maxIncome: 155050, taxRatePercentage: 4.75 },
    { stateCode: "RI", marriedRate: false, minIncome: 155051, maxIncome: Infinity, taxRatePercentage: 5.99 },
    { stateCode: "RI", marriedRate: true, minIncome: 0, maxIncome: 68200, taxRatePercentage: 3.75 },
    { stateCode: "RI", marriedRate: true, minIncome: 68201, maxIncome: 155050, taxRatePercentage: 4.75 },
    { stateCode: "RI", marriedRate: true, minIncome: 155051, maxIncome: Infinity, taxRatePercentage: 5.99 },

    // South Carolina
    { stateCode: "SC", marriedRate: false, minIncome: 0, maxIncome: 3190, taxRatePercentage: 0 },
    { stateCode: "SC", marriedRate: false, minIncome: 3191, maxIncome: 15900, taxRatePercentage: 3 },
    { stateCode: "SC", marriedRate: false, minIncome: 15901, maxIncome: 31920, taxRatePercentage: 4 },
    { stateCode: "SC", marriedRate: false, minIncome: 31921, maxIncome: 47880, taxRatePercentage: 5 },
    { stateCode: "SC", marriedRate: false, minIncome: 47881, maxIncome: Infinity, taxRatePercentage: 7 },
    { stateCode: "SC", marriedRate: true, minIncome: 0, maxIncome: 3190, taxRatePercentage: 0 },
    { stateCode: "SC", marriedRate: true, minIncome: 3191, maxIncome: 15900, taxRatePercentage: 3 },
    { stateCode: "SC", marriedRate: true, minIncome: 15901, maxIncome: 31920, taxRatePercentage: 4 },
    { stateCode: "SC", marriedRate: true, minIncome: 31921, maxIncome: 47880, taxRatePercentage: 5 },
    { stateCode: "SC", marriedRate: true, minIncome: 47881, maxIncome: Infinity, taxRatePercentage: 7 },

    // 
    { stateCode: "VA", marriedRate: false, minIncome: 0, maxIncome: 3000, taxRatePercentage: 2 },
    { stateCode: "VA", marriedRate: false, minIncome: 3001, maxIncome: 5000, taxRatePercentage: 3 },
    { stateCode: "VA", marriedRate: false, minIncome: 5001, maxIncome: 17000, taxRatePercentage: 5 },
    { stateCode: "VA", marriedRate: false, minIncome: 17001, maxIncome: Infinity, taxRatePercentage: 5.75 },
    { stateCode: "VA", marriedRate: true, minIncome: 0, maxIncome: 3000, taxRatePercentage: 2 },
    { stateCode: "VA", marriedRate: true, minIncome: 3001, maxIncome: 5000, taxRatePercentage: 3 },
    { stateCode: "VA", marriedRate: true, minIncome: 5001, maxIncome: 17000, taxRatePercentage: 5 },
    { stateCode: "VA", marriedRate: true, minIncome: 17001, maxIncome: Infinity, taxRatePercentage: 5.75 },

    // Vermont
    { stateCode: "VT", marriedRate: false, minIncome: 0, maxIncome: 41500, taxRatePercentage: 3.35 },
    { stateCode: "VT", marriedRate: false, minIncome: 41501, maxIncome: 99200, taxRatePercentage: 6.6 },
    { stateCode: "VT", marriedRate: false, minIncome: 99201, maxIncome: 206950, taxRatePercentage: 7.6 },
    { stateCode: "VT", marriedRate: false, minIncome: 206951, maxIncome: Infinity, taxRatePercentage: 8.75 },
    { stateCode: "VT", marriedRate: true, minIncome: 0, maxIncome: 69000, taxRatePercentage: 3.35 },
    { stateCode: "VT", marriedRate: true, minIncome: 69001, maxIncome: 119200, taxRatePercentage: 6.6 },
    { stateCode: "VT", marriedRate: true, minIncome: 119201, maxIncome: 251950, taxRatePercentage: 7.6 },
    { stateCode: "VT", marriedRate: true, minIncome: 251951, maxIncome: Infinity, taxRatePercentage: 8.75 },

    // Wisconsin
    { stateCode: "WI", marriedRate: false, minIncome: 0, maxIncome: 12230, taxRatePercentage: 3.54 },
    { stateCode: "WI", marriedRate: false, minIncome: 12231, maxIncome: 24250, taxRatePercentage: 4.65 },
    { stateCode: "WI", marriedRate: false, minIncome: 24251, maxIncome: 266930, taxRatePercentage: 5.3 },
    { stateCode: "WI", marriedRate: false, minIncome: 266931, maxIncome: Infinity, taxRatePercentage: 7.65 },
    { stateCode: "WI", marriedRate: true, minIncome: 0, maxIncome: 16310, taxRatePercentage: 3.54 },
    { stateCode: "WI", marriedRate: true, minIncome: 16311, maxIncome: 32290, taxRatePercentage: 4.65 },
    { stateCode: "WI", marriedRate: true, minIncome: 32291, maxIncome: 355910, taxRatePercentage: 5.3 },
    { stateCode: "WI", marriedRate: true, minIncome: 355911, maxIncome: Infinity, taxRatePercentage: 7.65 },


    { stateCode: "WV", marriedRate: false, minIncome: 0, maxIncome: 10000, taxRatePercentage: 3 },
    { stateCode: "WV", marriedRate: false, minIncome: 10001, maxIncome: 25000, taxRatePercentage: 4 },
    { stateCode: "WV", marriedRate: false, minIncome: 25001, maxIncome: 40000, taxRatePercentage: 4.5 },
    { stateCode: "WV", marriedRate: false, minIncome: 40001, maxIncome: 60000, taxRatePercentage: 6 },
    { stateCode: "WV", marriedRate: false, minIncome: 60001, maxIncome: Infinity, taxRatePercentage: 6.5 },
    { stateCode: "WV", marriedRate: true, minIncome: 0, maxIncome: 10000, taxRatePercentage: 3 },
    { stateCode: "WV", marriedRate: true, minIncome: 10001, maxIncome: 25000, taxRatePercentage: 4 },
    { stateCode: "WV", marriedRate: true, minIncome: 25001, maxIncome: 40000, taxRatePercentage: 4.5 },
    { stateCode: "WV", marriedRate: true, minIncome: 40001, maxIncome: 60000, taxRatePercentage: 6 },
    { stateCode: "WV", marriedRate: true, minIncome: 60001, maxIncome: Infinity, taxRatePercentage: 6.5 },

];

export const stateIncomeTaxPercentage = (stateCode: string, isMarried: boolean, income: number) => {

    const taxSystem: string = statesTaxSystems.find(stateTaxSystem => stateTaxSystem.stateCode === stateCode)?.taxSystem ?? "";

    if (taxSystem === 'none') return 0;

    else if (taxSystem === 'flat') {
        return flatTaxStates.filter(flatTaxState => flatTaxState.stateCode === stateCode)[0].taxRate;
    }

    else {
        const filteredStateTaxes = progressiveTaxStates.filter(progressiveTaxState => progressiveTaxState.stateCode === stateCode && progressiveTaxState.marriedRate === isMarried).sort(sorted => sorted.minIncome);

        if (filteredStateTaxes) {
            for (const filteredStateTax of filteredStateTaxes) {
                if (filteredStateTax.minIncome <= income)
                    return filteredStateTax.taxRatePercentage;
            }
        }
    }

    return 0;
}