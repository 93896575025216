import AppLayout from "../../Layouts/AppLayout";
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from "react"
import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Text,
    Button,
    StackDivider,
    InputGroup,
    InputLeftElement,
    Spinner,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Center
} from "@chakra-ui/react";
import {useAppSelector} from "../../Hooks/useAppSelector";
import {
    useCreateChargeMutation,
    useCreateCheckoutSessionMutation,
    useGetPriceByDocumentTypeQuery, useLazyGetPriceByDocumentTypeQuery
} from "../../Services";
import {useNavigate} from "react-router-dom";
import {DocumentMap, documentTypeEnums} from "../../Types/Enums/documentTypeEnums";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {getStripeKey} from "../../Services/utils";
import {useToast} from "../../Hooks/useToast";


const stripePromise = loadStripe(`${getStripeKey()}`);
const CheckoutPage = () => {
    const documentType = useAppSelector(state => state.document.documentType);
    const formType = useAppSelector(state => state.document.formType);
    const documentCount = useAppSelector(state => state.document.documentCount);
    const [fetchPrice, {data, isError: documentPriceError, isLoading, isSuccess: priceSuccess},] = useLazyGetPriceByDocumentTypeQuery();
    const [checkoutSession, { data: checkoutSessionData, isLoading: checkoutLoading }] = useCreateCheckoutSessionMutation();
    const [createCharge, { isLoading: chargeLoading }] = useCreateChargeMutation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { alert } = useToast();
    const price = useMemo(() => data?.documentPrice.newPrice, [data])



    useEffect(() => {
        fetchPrice(documentType)
    }, [documentType])
    useLayoutEffect(() => {
        // scrolls to top once component in view
        window.scrollTo({top:0, left:0, behavior: "auto"})
    }, []);

    const payWithCard = useCallback(() => {
        if (checkoutSessionData === undefined){
            checkoutSession({
                "checkoutSessionDto": {
                    "checkoutItemDtos": [{
                        "documentType": documentType,
                        "quantity": documentCount,
                        "unitPrice": 100 * (price ?? 0)
                    }],
                    currencyEmun: 0
                }
            }).then(()=> {
                onOpen()
            }).catch(()=> {
                alert("error", "Failed to create Checkout session")
            });
        } else {
            onOpen()
        }

    }, [data, checkoutSessionData, documentType, price])

    const payWithCrypto = () => {
        const amount =  (parseFloat(`${price ?? 0}`) * documentCount).toString()
        createCharge({
            'chargeRequest': {
                "name": documentTypeEnums[documentType],
                "description": '',
                "pricing_type": 'fixed_price',
                "local_price": {
                    "amount": amount,
                    "currency": 'USD'
                }
            }
        }).then((res) => {
            alert("success", "Navigating to payment portal")
            if (res.data !== undefined){
                window.location.href = res.data.chargeResponse.data.hosted_url
            }
        }).catch(()=> {
            alert("error", "Failed to create Charge")
        });
    }



    return (
        <AppLayout isForm>
            <Flex px={"36px"} py={"24px"} minH={"60vh"} direction={"column"}>
                <Flex py={"12px"} justifyContent={"center"} gap={"16px"} backgroundColor={"#9E9E9E24"} mb={"24px"} borderRadius={"4px"}>
                    <Text>
                        Checkout
                    </Text>
                    <Box w={"24px"} h={"24px"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"/>
                        </svg>
                    </Box>
                </Flex>
                {
                    isLoading && (
                        <Flex w={"100%"} h={"60vh"} grow={1} direction={"column"} justifyContent={"center"} flexGrow={'1'} alignItems={"center"}>
                            <Text mb={"24px"}>
                                Fetching Prices
                            </Text>
                            <Spinner/>
                        </Flex>
                    )
                }
                {
                    priceSuccess && (
                        <Flex gap={"20px"}>
                            <Box flexGrow={"1"}>
                                {
                                    formType ? DocumentMap({formType}) : <Center>No Document available for checkout</Center>
                                }
                            </Box>
                            <Box px={"20px"} py={"32px"} border={"1px"} borderRadius={"8px"} borderColor={"#B2BCCA"}
                                 flex={"none"}>
                                <FormControl>
                                    <FormLabel>
                                        What email would you like it to be sent to?
                                    </FormLabel>
                                    <InputGroup >
                                        <InputLeftElement>
                                            <Box w={"24px"} h={"24px"} color={"#667085"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"/>
                                                </svg>
                                            </Box>
                                        </InputLeftElement>
                                        <Input focusBorderColor={"primary"} placeholder={"max.luca@gmail.com "} type={"email"}/>
                                    </InputGroup>
                                </FormControl>
                                <VStack textColor={"#101828"} my={"24px"} px={"24px"} py={"20px"}
                                        divider={<StackDivider color={"#E0E0E0"}/>} border={"1px"} borderColor={"#B2BCCA"}
                                        borderRadius={"8px"}>
                                    <Box w={"100%"} px={"4px"}>
                                        <Text>
                                            Check out Summary
                                        </Text>
                                        <Flex justifyContent={"space-between"} my={"10px"} fontSize={"14px"}>
                                            <Text>{documentCount} items in cart</Text>
                                            <Text textColor={"#667085"}>${price}</Text>
                                        </Flex>
                                        <Flex gap={"8px"} fontSize={"14px"}>
                                            <Text>Type:</Text>
                                            <Text textColor={"#667085"}>{formType}</Text>
                                        </Flex>
                                    </Box>
                                    <Box w={'100%'} py={"14px"} px={"4px"}>
                                        <Flex justifyContent={"space-between"}>
                                            <Text>
                                                Total
                                            </Text>
                                            <Text>
                                                ${(price ?? 0) * documentCount}
                                            </Text>
                                        </Flex>
                                    </Box>
                                </VStack>
                                <Flex direction={"column"} gap={"16px"}>
                                    <Button onClick={() => payWithCard()}>
                                        {
                                            checkoutLoading && <Spinner/>
                                        } <Text mx={"8px"}>Pay with Card</Text>
                                    </Button>
                                    <Button onClick={() => payWithCrypto()}>
                                        {
                                            chargeLoading && <Spinner/>
                                        } <Text mx={"8px"}>Pay with Crypto</Text>
                                    </Button>
                                </Flex>
                            </Box>
                        </Flex>
                    )
                }
                {
                    !isLoading && documentPriceError && (
                        <Flex w={"100%"} h={"60vh"} direction={"column"} justifyContent={"center"} flexGrow={'1'} alignItems={"center"}>
                            <Text mb={"24px"}>
                                Unable to fetch prices of document refresh page
                            </Text>
                            <Button onClick={()=> fetchPrice(documentType)}>
                                Refresh
                            </Button>
                        </Flex>
                    )
                }
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <div id="checkout">
                            <EmbeddedCheckoutProvider stripe={stripePromise} options={{...checkoutSessionData}}>
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </AppLayout>
    )
}

export default CheckoutPage