import { PayloadAction } from "@reduxjs/toolkit";
import { DeductionType, PaymentMode, paymentScheduleInfo } from "../../../Types/Enums/PaymentModeEnum";
import {
    calculateEarningsYtd,
    calculateInitialYtd,
    getFIT,
    getMedicare,
    getPayPeriods, getSocialSecurity, getStateTax
} from "../../../Services/payStubUtils";
import { IEarning, IPaystubData } from "../../../Types/Interface/IPaystub";
import PayStub from "Pages/PayStubPage/PayStub";


const defaultAnnualSalary = 48000
const defaultHourlyRate = 20

export default function updatePaymentMode(state: IPaystubData, action: PayloadAction<PaymentMode>) {
    const paymentMode = action.payload
    state.info.paymentMode = paymentMode
    const paystubs = state.payStubs
    const maritalStatus = state.employee.maritalStatus
    const paySchedule = state.info.paySchedule
    const payPeriods = getPayPeriods(paySchedule)
    const isContractor = state.employee.isContractor

    // changes the default value
    if (paymentMode === PaymentMode.Salary) {
        state.info.salary = defaultAnnualSalary
    } else {
        state.info.hourlyRate = defaultHourlyRate
    }

    // creates a shallow copy of the paystubs
    const reversedStubs = [...paystubs].reverse()

    let initialFitYtd = 0
    let initialSocialYtd = 0
    let initialMedicareYtd = 0
    let initialStateYtd = 0
    const additionalDetailsYtd: { [k: number]: number } = {}

    reversedStubs.forEach((paystub, currentStubIndex) => {
        let currentEarning: IEarning
        if (paymentMode === PaymentMode.Salary) {
            const total = defaultAnnualSalary / getPayPeriods(paySchedule)
            currentEarning = {
                id: paystub.earnings[0].id,
                type: PaymentMode.Salary,
                description: "Salary",
                rate: undefined,
                hours: undefined,
                total,
                ytd: calculateEarningsYtd(
                    calculateInitialYtd(paySchedule, total, paystub.to), total)
            }

        } else {
            const hours = paymentScheduleInfo[paySchedule].hours
            const total = hours * defaultHourlyRate
            currentEarning = {
                id: paystub.earnings[0].id,
                type: PaymentMode.Hourly,
                description: "Salary",
                rate: state.info.hourlyRate,
                hours,
                total,
                ytd: calculateEarningsYtd(
                    calculateInitialYtd(paySchedule, total, paystub.to), total)
            }
        }
        Object.assign(paystub.earnings[0], currentEarning)
        const grossPay = paystub.earnings.reduce((acc, current) => acc + current.total, 0)
        Object.assign(
            paystub.grossPay,
            {
                amount: grossPay,
                ytd: paystub.earnings.reduce((acc, current) => acc + current.ytd, 0),
            })

        // deductions
        let netPay = grossPay;
        paystub.deductions.forEach((deduction, deductionIndex) => {
            let deductionAmount: number
            if (deduction.type === DeductionType.FederalTax) {
                if (isContractor)
                {
                    deductionAmount = 0
                }
                else {
                    deductionAmount = !paystub.enableManualTax ? getFIT(grossPay, paySchedule, maritalStatus) / payPeriods : deduction.amount ?? 0
                }

                initialFitYtd = currentStubIndex === 0 ? calculateInitialYtd(paySchedule, deductionAmount, paystub.to) + deductionAmount : initialFitYtd + deductionAmount
                deduction.ytd = initialFitYtd
            } else if (deduction.type === DeductionType.Medicare) {
                deductionAmount = !isContractor ? getMedicare(grossPay, paySchedule) / payPeriods : 0
                initialMedicareYtd = currentStubIndex === 0 ? calculateInitialYtd(paySchedule, deductionAmount, paystub.to) + deductionAmount : initialMedicareYtd + deductionAmount
                deduction.ytd = initialMedicareYtd
            } else if (deduction.type === DeductionType.SocialSecurity) {
                deductionAmount = !isContractor ? getSocialSecurity(grossPay, paySchedule) / payPeriods : 0
                initialSocialYtd = currentStubIndex === 0 ? calculateInitialYtd(paySchedule, deductionAmount, paystub.to) + deductionAmount : initialSocialYtd + deductionAmount
                deduction.ytd = initialSocialYtd
            } else if (deduction.type === DeductionType.StateTax) {

                if (isContractor) {
                    deductionAmount = 0
                }
                else {
                    deductionAmount = !paystub.enableManualTax ? getStateTax(grossPay, paySchedule, maritalStatus, state.employee.taxState) : deduction.amount ?? 0
                }
                initialStateYtd = currentStubIndex === 0 ? calculateInitialYtd(paySchedule, deductionAmount, paystub.to) + deductionAmount : initialStateYtd + deductionAmount
                deduction.ytd = initialStateYtd
            } else {
                deductionAmount = deduction.amount ?? 0
                deduction.ytd = additionalDetailsYtd[deductionIndex] ? additionalDetailsYtd[deductionIndex] + deductionAmount : deductionAmount 
            }
            deduction.amount = deductionAmount
            netPay -= deductionAmount
        })

        paystub.netPay = netPay
        paystub.check = -netPay
        paystub.netCheck = 0
    })

}