import {useAppSelector} from "../Hooks/useAppSelector";
import {Button, Center} from "@chakra-ui/react";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View} from "@react-pdf/renderer";
import AppDocumentViewer from "../Components/AppDocumentViewer";
import React, {useEffect, useState} from "react";
import {IDeduction, IEarning, IPaystubData} from "../Types/Interface/IPaystub";
import PdfWaterMark from "../Components/PdfWaterMark";
import {selectPaystubData, setStubsDownloaded} from "../Store/Slices/paystubSlice";
import converter from "number-to-words";
import {format} from "date-fns";
import useStubDetails from "../Hooks/useStubDetails";
import {formatAddress} from "../Services/utils";
import {IDocumentCheckout} from "../Types/Interface/IRequestBody";
import {reqClient} from "../Api/reqClient";
import {DeductionType, MaritalStatus, PaymentMode} from "../Types/Enums/PaymentModeEnum";
import {useAppDispatch} from "../Hooks/useAppDispatch";


const styles = StyleSheet.create({
    general: {
        fontFamily: "Inter",
        color: "#000000",
        fontSize: "12px"
    },
    tableHeading: {
        width: "auto",
        margin: 0,
        borderBottom: "1px solid black",
        padding: "6px 0px",
        textTransform: "capitalize",
        fontWeight: "medium"
    },
    tableData: {
        minWidth: "40px",
        width: "max-content",
        margin: 0,
        padding: "8px 0px"
    },
    tableColoredFooter: {
        backgroundColor: "rgba(0, 0, 0, 0.2)", padding: "9px 0px", borderBottomColor: "black", borderTopColor: "black", borderTopWidth: "1px"
    },
    tableCellSpace: { width: "14px", padding: 0, borderBottom: 0 },
    coloredCell: {
        padding: "9px 0px", borderTop: "1px solid black", borderBottom: "1px solid black", backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    paddedCell: {
        padding: "10px 0px",
    }
})

const checkOutPayStub = async (session: IDocumentCheckout, data: IPaystubData) => {
    const {checkoutMode, sessionId, amount} = session
    const url = `/create_paystub/${sessionId}/${amount}/${checkoutMode}`
    const body = {
        payStubDto: {
            company: {
                ...data.company,
                isCompletedForm: true,
                address: {
                    id: 0,
                    addressLine1: data.company.address,
                    addressLine2: "",
                    postalCode: data.company.zipcode,
                    city: data.company.city,
                    regionOrState: data.company.state,
                    country: "united state",
                    isCompletedForm: true,
                },
                websiteUrl: "",
                phoneNumber: "",
                ein: "",
                taxId: "",
                isContractor: data.employee.isContractor
                },
            employee: {
                id: 0,
                name: data.employee.name,
                firstName: data.employee.name,
                lastName: "",
                middleName: "",
                addressLine1: data.employee.address,
                zip: data.employee.zipcode,
                city: data.employee.city,
                state: data.employee.taxState,
                ssn: data.employee.ssn,
                employeeNumber: "",
                role: "",
                email: "",
                phoneNumber: "",
                isMarried: data.employee.maritalStatus === MaritalStatus.Married
            },
            schedule: 1,
            paymentMode: data.info.paymentMode === PaymentMode.Hourly ? 0 : 1,
            hourlyRate: PaymentMode.Hourly === data.info.paymentMode ? data.info.hourlyRate : data.info.salary,
            earnings: data.payStubs.map((payStub, index) => {
                const medicare = payStub.deductions.find((deduction) => deduction.type === DeductionType.Medicare)
                const socialSecurity = payStub.deductions.find((deduction) => deduction.type === DeductionType.SocialSecurity)
                const incomeFedTax = payStub.deductions.find((deduction) => deduction.type === DeductionType.FederalTax)
                const stateTax = payStub.deductions.find((deduction) => deduction.type === DeductionType.StateTax)
                return  {
                    id: index,
                    isCompletedForm: true,
                    employeeId: 0,
                    companyId: 0,
                    payDate: new Date(payStub.payDate).toISOString(),
                    from: new Date(payStub.from).toISOString(),
                    to: new Date(payStub.to).toISOString(),
                    totalHours: data.info.paymentMode === PaymentMode.Hourly ? payStub.earnings[0].hours : 1,
                    additionalEarnings: payStub.earnings.slice(1).map((earning) => (
                        {
                            description: earning.description,
                            rate: earning.rate,
                            totalHoursOrQuantity: earning.hours,
                            earningStubId: index,
                            employeeId: 0
                        }
                    )),
                    customDeductions: payStub.deductions.slice(4).map((deduction) => (
                        {
                            id: 0,
                            isCompletedForm: true,
                            total: deduction.amount,
                            earningStubId: index,
                            description: deduction.description,
                        }
                    )),
                    medicare: medicare?.amount,
                    stateTax: stateTax?.amount,
                    socialSecurity: socialSecurity?.amount,
                    federalTax: incomeFedTax?.amount,
                    exemption_allowance: 0,
                    adviceNumber: 0,
                }
                }
            )
        }
    }
    const response = await reqClient.post(url, body)
    const resData = response.data
    return resData.isSuccess ?? false
}

const PaystubDocument = ({ hasPaid, session}: {hasPaid?: boolean, session?: IDocumentCheckout}) => {
    const data = useAppSelector(selectPaystubData)
    const [checkoutLoading, setCheckOutLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(()=>{
        if (hasPaid && session){
            setCheckOutLoading(true);
            checkOutPayStub(session, data).then((res)=>{
                setIsSuccess(res)
            }).catch(()=>{

            }).finally(()=>{
                setCheckOutLoading(false)
            })
        }
    }, [session, hasPaid])

    if (hasPaid && session) {
        return (
            <>
                {
                    checkoutLoading ? (
                        <Button>
                            Verifying payment...
                        </Button>
                    ) : (
                        isSuccess ? (
                            <Button>
                                <PDFDownloadLink onClick={() => dispatch(setStubsDownloaded())} document={<Paystubs payStubData={data} hasPaid={hasPaid}/>} fileName={"paystubs.pdf"}>
                                    <div>
                                        Download Paystubs
                                    </div>
                                </PDFDownloadLink>
                            </Button>
                        ) : <Center>An error occurred, could not verify payment</Center>
                    )
                }
            </>
        )
    }


    return (
        <AppDocumentViewer>
            <Paystubs payStubData={data}/>
        </AppDocumentViewer>
    )
}

const Paystubs = ({payStubData, hasPaid}: {payStubData: IPaystubData, hasPaid?: boolean}) => {
    const {payStubs, employee, company} = payStubData

  return (
      <Document>
          {
              payStubs.map((paystub) => {
                  const {
                      overtimes, holidays, vacations,
                      bonuses, tips, medicare, socialSecurity,
                      incomeFedTax, stateTax, additionalEarnings, additionalDeductions
                  } = useStubDetails(paystub)
                  const {netPay,} = paystub
                  const cents = netPay.toFixed(2).split(".")[1]
                  return (
                      <Page key={paystub.id} size={{width: "782"}} style={{fontFamily: "Inter"}}>
                          {
                              !hasPaid ? <PdfWaterMark fontSize={"150px"}/> : <Text></Text>
                          }
                          <View  style={{flexDirection: "row", paddingHorizontal: "16px", justifyContent: "space-between", alignItems: "flex-end"}}>
                              <View  style={{flexBasis: "55%"}} >
                                  <View  style={{flexDirection: "column", justifyContent: "flex-end", height: "79px", width: "155px", marginTop: "25px", marginBottom: "40px"}} >
                                      {
                                          company.logo ? <Image src={company.logo} style={{objectFit: "contain"}} source={"Logo"}/> : <Text></Text>
                                      }
                                  </View>
                                  <View style={{width: "139px", paddingRight: "12px", fontSize: "12px", color: "#1a1c12", fontWeight: "medium"}}>
                                      <Text>{company.name}</Text>
                                      <Text>{formatAddress(company.address)}</Text>
                                      <Text>{formatAddress(company.city)} {company.state} {company.zipcode}</Text>
                                  </View>
                              </View>
                              <View  style={{flexBasis: "40%"}} >
                                  <Text  style={{fontSize: "24px", textAlign: "right", fontWeight: "semibold"}}>Earning Statement</Text>
                                  <View  style={{fontSize: "12px", marginVertical: "24px", paddingRight: "8px", fontWeight: "medium"}} >
                                      <View style={{flexDirection: "row", justifyContent: "space-between"}}><Text style={{color: "#1a1c21"}}>Period Beginning</Text> <Text
                                          color={"#5e6470"}>{format(paystub.from, "dd/MM/yyyy")}</Text></View>
                                      <View style={{flexDirection: "row", justifyContent: "space-between"}}><Text style={{color: "#1a1c21"}}>Period Ending</Text> <Text
                                          color={"#5e6470"}>{format(paystub.to, "dd/MM/yyyy")}</Text></View>
                                      <View style={{flexDirection: "row", justifyContent: "space-between"}}><Text style={{color: "#1a1c21"}}>Pay Date</Text> <Text
                                          color={"#5e6470"}>{format(paystub.payDate, "dd/MM/yyyy")}</Text></View>
                                  </View>
                              </View>
                          </View>
                          <View  style={{flexDirection: "row", paddingHorizontal: "16px", marginTop: "45px", alignItems: "flex-start", justifyContent: "space-between"}}>
                              <View style={{flexBasis: "40%", fontSize: "12px", color: "black"}}>
                                  <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                                      <Text>Social Security Number:</Text>
                                      <Text>XXX-XX-{employee.ssn.length === 0 ? 1234 : employee.ssn}</Text>
                                  </View>
                                  <View  style={{flexDirection: "row", justifyContent: "space-between"}} ><Text>Taxable Marital Status:</Text> <Text>{employee.maritalStatus}</Text></View>
                                  <View  style={{flexDirection: "row", justifyContent: "space-between"}} ><Text>Exemptions / Allowances</Text> <Text>0</Text></View>
                                  <View  style={{flexDirection: "row", justifyContent: "space-between"}} ><Text>Employee ID:</Text> <Text>{employee.eid}</Text></View>
                              </View>
                              <View style={{flexBasis: "40%", fontWeight: "medium", color: "#1a1c21"}}>
                                  <View style={{width: "139px", paddingRight: "8px", fontSize: "12px"}} >
                                      <Text>{employee.name}</Text>
                                      <Text>{formatAddress(employee.address)}</Text>
                                      <Text>{formatAddress(employee.city)} {employee.taxState} {employee.zipcode}.</Text>
                                  </View>
                              </View>
                          </View>
                          <View  style={{minHeight: "358px", marginTop: "24px", paddingHorizontal: "16px"}}>
                              <View style={{flexDirection: "row", justifyContent: "space-between", gap: "16px"}} >
                                  <View  style={[styles.general, {flexGrow: 1}]}>
                                      <View>
                                          <View style={[{flexDirection: "row"}]}>
                                              <Text style={[ styles.tableHeading, {width: "116px"} ]}>Earnings</Text>
                                              <Text style={[styles.tableHeading, {width: "70px"}]}>Rate</Text>
                                              <View style={[styles.tableHeading, {flexDirection: "row", flexGrow: 1, justifyContent: "space-between"}]}>
                                                  <Text style={[{width: "100px"} ]}>Hours</Text>
                                                  <Text style={{textAlign: "right"}}>Amount</Text>
                                              </View>
                                              <View style={styles.tableCellSpace}></View>
                                              <Text style={[styles.tableHeading, {textAlign: "right", width: "100px"} ]}>Year to date</Text>
                                          </View>
                                      </View>
                                      <View style={{marginBottom: "24px"}}>
                                          <EarningRow earning={paystub.earnings[0]} />
                                          {
                                              overtimes.map((overtime) => <EarningRow key={overtime.id} earning={overtime} />)
                                          }
                                          {
                                              holidays.map((holiday) => <EarningRow key={holiday.id} earning={holiday} />)
                                          }
                                          {
                                              vacations.map((vacation) => <EarningRow key={vacation.id} earning={vacation} />)
                                          }
                                          {
                                              bonuses.map((bonus) => <EarningRow key={bonus.id} earning={bonus} />)
                                          }
                                          {
                                              tips.map((tip) => <EarningRow key={tip.id} earning={tip} />)
                                          }


                                          <View style={{ padding: 0, flexDirection: "row" }}>
                                              <Text style={{width: "186px"}}></Text>
                                              <View style={[styles.coloredCell, {flexDirection: "row", flexGrow: 1, justifyContent: "space-between"}]}>
                                                  <Text>GROSS PAY</Text>
                                                  <Text>{paystub.grossPay.amount.toFixed(2)}</Text>
                                              </View>
                                              <View style={styles.tableCellSpace}></View>
                                              <Text style={[styles.paddedCell, {textAlign: "right", width: "100px"}]}>{paystub.grossPay.ytd.toFixed(2)}</Text>
                                          </View>
                                          <View>
                                              <View style={{ padding: "8px 0px", borderBottom: 0 }}></View>
                                          </View>
                                      </View>
                                      <View>
                                          <View style={{flexDirection: "row"}}>
                                              <Text style={[styles.tableHeading, {width: "116px"}]}>Deduction</Text>
                                              <Text style={[styles.tableHeading,]}>Statutory</Text>
                                              <View style={[styles.tableHeading, {flexGrow: 1}]}></View>
                                              <View style={styles.tableCellSpace}></View>
                                              <View style={[styles.tableHeading, {width: "100px"}]}></View>
                                          </View>
                                      </View>
                                      <View>
                                          {
                                              incomeFedTax ? <DeductionRow deduction={incomeFedTax} /> : <Text></Text>
                                          }
                                          {
                                              socialSecurity ? <DeductionRow deduction={socialSecurity} /> : <Text></Text>
                                          }
                                          {
                                              medicare ? <DeductionRow deduction={medicare} /> : <Text></Text>
                                          }
                                          {
                                              stateTax ? <DeductionRow deduction={stateTax} /> : <Text></Text>
                                          }
                                          <View>
                                              <View style={{ padding: "8px 0px", borderBottom: 0 }}></View>
                                          </View>
                                          {
                                              additionalDeductions.length > 0 ?
                                                  <View>
                                                  <View style={{flexDirection: "row"}} >
                                                      <Text style={{width: "116px"}}></Text>
                                                      <Text style={{textDecoration: "underline", paddingVertical: "8px"}}>Other</Text>
                                                  </View>
                                                  {
                                                      additionalDeductions.map((deduction) => <DeductionRow key={deduction.id} deduction={deduction} />)
                                                  }
                                              </View>  : <Text></Text>
                                          }

                                          <View style={{flexDirection: "row"}}>
                                              <View style={{width: "116px"}}></View>
                                              <View style={[styles.coloredCell, {flexDirection: "row", flexGrow: 1, justifyContent: "space-between"}]}>
                                                  <Text>NET PAY</Text>
                                                  <Text>{paystub.netPay.toFixed(2)}</Text>
                                              </View>
                                              <View style={styles.tableCellSpace}></View>
                                              <View style={{width: "100px"}}></View>
                                          </View>
                                          <View style={{flexDirection: "row"}}>
                                              <View style={{width: "116px"}}></View>
                                              <View style={{flexDirection: "row", flexGrow: 1, justifyContent: "space-between", paddingVertical: "10px"}}>
                                                  <Text >CHECK</Text>
                                                  <Text>{paystub.check.toFixed(2)}</Text>
                                              </View>
                                              <View style={styles.tableCellSpace}></View>
                                              <View style={{width: "100px"}}></View>
                                          </View>
                                          <View style={{flexDirection: "row"}}>
                                              <View style={{width: "116px"}}></View>
                                              <View style={[styles.coloredCell, {flexDirection: "row", flexGrow: 1, justifyContent: "space-between"}]}>
                                                  <Text>NET CHECK</Text>
                                                  <Text>{paystub.netCheck.toFixed(2)}</Text>
                                              </View>
                                              <View style={styles.tableCellSpace}></View>
                                              <View style={{width: "100px"}}></View>
                                          </View>
                                      </View>
                                  </View>

                                  <View style={[styles.general, ]}>
                                      <View style={{flexDirection: "row", width: "auto"}}>
                                          <View style={[styles.tableHeading, {flexDirection: "row", justifyContent: "space-between", width: "166px"}]}>
                                              <Text>Other benefit</Text>
                                              <Text>This period</Text>
                                          </View>
                                          <View style={{width: "14px"}}></View>
                                          <Text style={[styles.tableHeading, {paddingHorizontal: "8px"}]}>Year to date</Text>
                                      </View>
                                      <View>
                                          {
                                              additionalEarnings.map(earning => <AdditionalEarningRow key={earning.id} earning={earning} />)
                                          }
                                      </View>
                                  </View>
                              </View>
                          </View>
                          <View  style={{paddingHorizontal: "16px", flexDirection: "row", justifyContent: "flex-end", height: "179px", alignItems: "center", marginBottom: "24px"}}>
                              <View style={{flexBasis: "50%", paddingRight: "36px", fontSize: "12px", color: "#1a1c21", fontWeight: "medium"}}>
                                  <View style={{flexDirection: "row", justifyContent: "space-between", marginBottom: "2px"}}>
                                      <Text>Pay Date</Text>
                                      <Text style={{color: "#5e6470"}} >{format(paystub.payDate, "dd/MM/yyyy")}</Text>
                                  </View>
                                  <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                                      <Text>Social security number</Text>
                                      <Text style={{color: "#5e6470"}}>XXX-XX-{employee.ssn}</Text>
                                  </View>
                              </View>
                          </View>
                          <View  style={{fontSize: "12px", paddingTop: "46px", paddingBottom: "89px", paddingHorizontal: "66px", backgroundColor: "#f9fafc"}}>
                              <Text style={{fontWeight: "medium", marginBottom: "8px"}}>
                                  Earnings
                              </Text>
                              <View style={{borderTopWidth: "0.69px", borderColor: "black", flexDirection: "row"}}>
                                  <View style={{paddingVertical: "8px", paddingLeft: "4px", paddingRight: "16px"}}>
                                      <Text>
                                          This amount
                                      </Text>
                                  </View>
                                  <View style={{fontWeight: "semibold", flexGrow: 1, borderBottomWidth: "0.69px", borderColor: "black", flexDirection: "row"}}>
                                      <View style={{paddingVertical: "8px", flexBasis: "80%"}}>
                                          <Text style={{paddingLeft: "16px", textTransform: "uppercase"}}>
                                              {
                                                  `${converter.toWords(parseInt(netPay.toString()))} Dollars ${cents !== "00" ? "And" : ""} ${cents !== "00" ? converter.toWords(cents) : ""} ${cents !== "00" ? "Cents" : ""}`
                                              }
                                          </Text>
                                      </View>
                                      <View style={{paddingLeft: "36px", paddingVertical: "8px", flexBasis: "20%", borderLeftWidth: "0.69px", borderColor: "black"}}>
                                          <Text>
                                              ${netPay.toFixed(2)}
                                          </Text>
                                      </View>
                                  </View>
                              </View>
                          </View>
                      </Page>
                  )
              })
          }
      </Document>
  )
}

const DeductionRow = ({ deduction }: { deduction: IDeduction }) => {
    return (
        <View style={{flexDirection: "row", paddingVertical: "8px"}}>
            <View style={{width: "116px"}}></View>
            <View style={{flexDirection: "row", flexGrow: 1, justifyContent: "space-between"}}>
                <Text>{deduction.description}</Text>
                <Text>{deduction.amount?.toFixed(2)}</Text>
            </View>
            <View style={styles.tableCellSpace}></View>
            <Text style={{width: "100px", textAlign: "right"}}>{deduction.ytd.toFixed(2)}</Text>
        </View>
    )
}

const EarningRow = ({ earning }: { earning: IEarning }) => {
    return (
        <View style={{flexDirection: "row", padding: "8px 0px"}}>
            <Text style={{width: "116px"}}>{earning.description}</Text>
            <Text style={{width: "70px"}}>{earning.rate}</Text>
            <View style={{flexDirection: "row", flexGrow: 1, justifyContent: "space-between"}}>
                <Text style={{width: "100px",}}>{earning.hours}</Text>
                <Text >{earning.total.toFixed(2)}</Text>
            </View>
            <View style={styles.tableCellSpace}></View>
            <Text style={{textAlign: "right", width: "100px"}} >{earning.ytd.toFixed(2)}</Text>
        </View>
    )
}
const AdditionalEarningRow = ({ earning }: { earning: IEarning }) => {
    return (
        <View style={{flexDirection: "row", paddingVertical: "8px"}}>
            <View style={ {flexDirection: "row", justifyContent: "space-between", width: "166px"}}>
                <Text >{earning.description}</Text>
                <Text>{earning.total.toFixed(2)}</Text>
            </View>
            <View style={{width: "14px"}}></View>
            <Text style={{paddingHorizontal: "8px"}}>{earning.ytd.toFixed(2)}</Text>
        </View>
    )
}



export default PaystubDocument