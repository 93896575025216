import {Button, Center} from "@chakra-ui/react";
import {PDFDownloadLink, Document, Page, View, Image, Text, Svg, Path} from "@react-pdf/renderer";
import AppDocumentViewer from "../Components/AppDocumentViewer";
import React, {useEffect, useState} from "react";
import {IQuotes} from "../Types/Interface/IQuotes";
import PdfWaterMark from "../Components/PdfWaterMark";
import {useAppSelector} from "../Hooks/useAppSelector";
import {useCalculateInvoice} from "../Hooks";
import {currencyFormatter, formatAddress} from "../Services/utils";
import {IDocumentCheckout} from "../Types/Interface/IRequestBody";
import {reqClient} from "../Api/reqClient";
import {setDocumentDownloaded} from "../Store/Slices";
import {useAppDispatch} from "../Hooks/useAppDispatch";

const checkOutQuote = async (session: IDocumentCheckout, data: IQuotes): Promise<boolean> => {
    const {checkoutMode, sessionId, amount} = session
    const url = `/Quotes/${sessionId}/${amount}/${checkoutMode}`
    const body = {
        taxAmount: data.taxAmount,
        discountAmount: data.discountAmount,
        discountPercentage: data.discountPercentage,
        from: {
            id: data.from.id,
            isCompletedForm: true,
            address: {
                id: data.from.address.id,
                isCompletedForm: true,
                addressLine1: data.from.address.addressLine1,
                addressLine2: data.from.address.addressLine2,
                postalCode: data.from.address.postalCode,
                city: data.from.address.city,
                regionOrState: data.from.address.regionOrState,
                country: "United State"
            },
            name: data.from.name,
            email: data.from.email,
            websiteUrl: data.from.websiteUrl,
            phoneNumber: data.from.phoneNumber,
            logo: data.from.logo,
            ein: "",
            taxId: "",
            isContractor: true
        },
        "for": {
            id: data.for.id,
            isCompletedForm: true,
            address: {
                id: data.for.address.id,
                isCompletedForm: true,
                addressLine1: data.for.address.addressLine1,
                addressLine2: data.for.address.addressLine2,
                postalCode: data.for.address.postalCode,
                city: data.for.address.city,
                regionOrState: data.for.address.regionOrState,
                country: "United state"
            },
            name: data.for.name,
            email: data.for.email,
            websiteUrl: data.for.websiteUrl,
            phoneNumber: data.for.phoneNumber,
            logo: data.for.logo,
            ein: "",
            taxId: "",
            isContractor: true
        },
        lineItems: data.lineItems.map((lineItem) => ({...lineItem, isCompletedForm: true})),
        "quoteHtmlString": "",
        "email": data.from.email
    }
    const response = await reqClient.post(url, body)
    const resData = response.data
    return resData.isSuccess ?? false
}


const QuoteDocument = ({hasPaid, session}: {hasPaid?: boolean, session?: IDocumentCheckout}) => {
    const document: IQuotes | null = useAppSelector((state) => state.document.documentData) as IQuotes;
    const dispatch = useAppDispatch()
    const logo = useAppSelector((state) => state.document.logo)
    const [checkoutLoading, setCheckOutLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false)

    const setDownloadedFlag = ()=>{dispatch(setDocumentDownloaded())}

    useEffect(()=>{
        if (hasPaid && session){
            setCheckOutLoading(true);
            checkOutQuote(session, document).then((res)=>{
                setIsSuccess(res)
            }).catch(()=>{

            }).finally(()=>{
                setCheckOutLoading(false)
            })
        }
    }, [session, hasPaid])

    if (hasPaid && session) {
        return (
            <>
                {
                  checkoutLoading ? (
                      <Button>
                          Verifying payment...
                      </Button>
                      ) : (
                      isSuccess ? (
                          <Button>
                              <PDFDownloadLink onClick={setDownloadedFlag} document={<Quote document={document} logo={logo} hasPaid={hasPaid}/>} fileName={"Quote.pdf"}>
                                  <div>
                                      Download Quote
                                  </div>
                              </PDFDownloadLink>
                          </Button>
                      ) : <Center>An error occurred, could not verify payment</Center>
                  )
                }
            </>
        )
    }

    return (
        <AppDocumentViewer>
            <Quote document={document} logo={logo}/>
        </AppDocumentViewer>
    )
}

const Quote = ({logo, hasPaid, document}: { document: IQuotes, logo?: string, hasPaid?: boolean}) => {
    const { subtotal, discount, itemsLineTotal, tax, total } = useCalculateInvoice(
        document.lineItems,
        document.discountPercentage,
        document.discountAmount,
        document.taxAmount
    );

    return (
        <Document>
            <Page size={[1000, 1417]} style={{flexDirection: "column", fontFamily: "Inter"}}>
                {
                    !hasPaid && <PdfWaterMark/>
                }
                <View style={{padding: "27px 53px", backgroundColor: "#F9FAFC", }}>
                    <View style={{flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between", marginBottom: "18px"}}>
                        <View style={{width: "81px", height: "81px"}}>
                            {
                                logo && (
                                    <Image src={logo} source={"logo"}/>
                                )
                            }
                        </View>
                        <Text style={{fontSize: "48px", textTransform: "uppercase", color: "#1A1C21"}}>
                            Quote
                        </Text>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                        <View>
                            <Text style={{fontSize: "16px", fontWeight: "semibold", color: "#1A1C21", marginBottom: "6px"}}>
                                Billed to
                            </Text>
                            <Text style={{fontSize: "16px", fontWeight: "semibold", color: "#5E6470"}}>{document.for.name}</Text>
                            <Text style={{fontSize: "16px", color: "#5E6470"}}>{document.for.address.addressLine1}</Text>
                            <Text style={{fontSize: "16px", color: "#5E6470"}}>{formatAddress(document.for.address.city)} {document.for.address.regionOrState} {document.for.address.postalCode}</Text>
                            <Text style={{fontSize: "16px", color: "#5E6470"}}>{document.for.email}</Text>
                            <Text style={{fontSize: "16px", color: "#5E6470"}}>{document.for.phoneNumber}</Text>
                        </View>
                        <View style={{alignItems: "flex-end"}}>
                            <Text style={{fontSize: "20px", fontWeight: "bold", color: "#4C63ED"}}>{document.from.name}</Text>
                            <Text style={{fontSize: "16px", color: "#5E6470"}}>{document.from.address.addressLine1}</Text>
                            <Text style={{fontSize: "16px", color: "#5E6470"}}>{formatAddress(document.from.address.city)} {document.from.address.regionOrState} {document.from.address.postalCode}</Text>
                        </View>
                    </View>
                </View>
                <View style={{flexGrow: 1, padding: "47px 0px", flexDirection: "row", justifyContent: "center"}}>
                    <View style={{height: "100%"}}>
                        <View style={{width: "705px", flexGrow: 1, borderRadius: "20px", border: "1px solid #D7DAE0", overflow: "hidden"}}>
                            <View style={{borderBottom: "1px solid #D7DAE0", padding: "0px 24px" }}>
                                <View style={{flexDirection: "row",  height: "57px", width: "100%"}}>
                                    <View style={{width: "200px", height: "100%", alignItems: "center", flexDirection: "row", }}>
                                        <Text style={{fontSize: "17px"}}>Services</Text>
                                    </View>
                                    <View style={{width: "110px", height: "100%", alignItems: "center", flexDirection: "row", justifyContent: "center"}}>
                                        <Text style={{fontSize: "17px"}}>Qty</Text>
                                    </View>
                                    <View style={{width: "150px", height: "100%", alignItems: "center", flexDirection: "row", justifyContent: "flex-end"}}>
                                        <Text style={{fontSize: "17px"}}>Rate</Text>
                                    </View>
                                    <View style={{height: "100%", flexGrow: 1, alignItems: "center", flexDirection: "row", justifyContent: "flex-end"}}>
                                        <Text style={{fontSize: "17px"}}>Line total</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{flexGrow: 1, padding: "0px 24px",}}>
                                {
                                    document.lineItems.map((item, index) => (
                                        <LineItem
                                            key={index}
                                            description={item.description}
                                            hasBottomBorder={index !== document.lineItems.length - 1}
                                            quantity={item.quantity}
                                            unitPrice={item.rate}
                                            amount={itemsLineTotal[index].lineTotal}
                                        />
                                    ))
                                }

                            </View>
                            <View style={{
                                borderTop: "1px solid #D7DAE0",
                                padding: "16px 0px"
                            }}>
                                <View style={{justifyContent: "space-between", flexDirection: "row",  padding: "0px 24px", }}>
                                    <Text style={{color: "#1A1C21", fontWeight: "medium"}}>Subtotal</Text>
                                    <Text style={{color: "#5E6470", fontWeight: "medium"}}>{currencyFormatter('USD', subtotal.toFixed(2))}</Text>
                                </View>
                                <View style={{justifyContent: "space-between", margin: "16px 0px", flexDirection: "row",  padding: "0px 24px",}}>
                                    <Text style={{color: "#1A1C21", fontWeight: "medium"}}>Discount</Text>
                                    <Text style={{color: "#5E6470", fontWeight: "medium"}}>{discount !== undefined && discount !== null ? currencyFormatter('USD', Number(discount).toFixed(2)) : 0}</Text>
                                </View>
                                <View style={{justifyContent: "space-between", flexDirection: "row",  padding: "0px 24px",}}>
                                    <Text style={{color: "#1A1C21", fontWeight: "medium"}}>Tax({document.taxAmount}%)</Text>
                                    <Text style={{color: "#5E6470", fontWeight: "medium"}}>{currencyFormatter('USD', tax.toFixed(2))}</Text>
                                </View>
                            </View>
                            <View style={{
                                justifyContent: "space-between",
                                flexDirection: "row",
                                height: "57px",
                                alignItems: "center",
                                backgroundColor: "#F9FAFC",
                                borderTop: "1px solid #D7DAE0",
                                padding: "0px 24px",
                                borderBottomLeftRadius: "20px",
                                borderBottomRightRadius: "20px",
                                color: "#4C63ED",
                                fontWeight: "bold"
                            }}>
                                <Text>Total</Text>
                                <Text>US{currencyFormatter('USD', total.toFixed(2))}</Text>
                            </View>
                        </View>
                        <View style={{margin: "16px", flexDirection: "row", justifyContent: "center",  color: "#5E6470", alignItems: "center", gap: "0px 8px"}}>
                            <Svg width="17" height="18" viewBox="0 0 17 18" fill="none">
                                <Path fillRule="evenodd"
                                      d="M3.4488 0.441895C1.58993 0.441895 0.0830078 1.94881 0.0830078 3.80769V13.9051C0.0830078 15.764 1.58993 17.2709 3.4488 17.2709H13.5462C15.4051 17.2709 16.912 15.764 16.912 13.9051V3.80769C16.912 1.94881 15.4051 0.441895 13.5462 0.441895H3.4488ZM8.03001 5.41499C8.03001 5.12457 7.79312 4.88498 7.50577 4.92774C5.6886 5.19762 4.29025 6.85032 4.29025 8.84879V12.2518C4.29025 12.5422 4.52573 12.7777 4.81616 12.7777H7.50411C7.79454 12.7777 8.03001 12.5422 8.03001 12.2518V9.86966C8.03001 9.57923 7.79454 9.34375 7.50411 9.34375H6.1602V8.77804C6.1602 7.87504 6.73104 7.11327 7.51168 6.87536C7.7894 6.79062 8.03001 6.56285 8.03001 6.27229V5.41499ZM12.7047 5.41499C12.7047 5.12457 12.4679 4.88498 12.1805 4.92774C10.3633 5.19762 8.96498 6.85032 8.96498 8.84879V12.2518C8.96498 12.5422 9.20046 12.7777 9.49089 12.7777H12.1788C12.4693 12.7777 12.7047 12.5422 12.7047 12.2518V9.86966C12.7047 9.57923 12.4693 9.34375 12.1788 9.34375H10.8348V8.77804C10.8348 7.87517 11.4058 7.11327 12.1863 6.87536C12.4641 6.79062 12.7047 6.56285 12.7047 6.27229V5.41499Z"
                                      fill="#8B919E"/>
                            </Svg>

                            <Text>
                                Please pay within 15 days of receiving this invoice.
                            </Text>
                        </View>
                    </View>

                </View>
                <View style={{
                    height: "117px",
                    backgroundColor: "#F9FAFC",
                    color: "#5E6470",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 53px"
                }}>
                    <Text>{document.from.websiteUrl}</Text>
                    <Text>{document.from.phoneNumber}</Text>
                    <Text>{document.from.email}</Text>
                </View>
            </Page>
        </Document>
    )
}

const LineItem = ({quantity, amount, description, unitPrice, hasBottomBorder}: {
    quantity?: number,
    description?: string,
    unitPrice?: number,
    amount?: number,
    hasBottomBorder?: boolean
}) => {
    return (
        <View style={{flexDirection: "row", borderBottom: hasBottomBorder ? "1px solid #D7DAE0" : undefined, height: "57px", width: "100%"}}>
            <View style={{width: "200px", height: "100%", alignItems: "center", flexDirection: "row",}}>
                <Text style={{fontSize: "17px"}}>{description}</Text>
            </View>
            <View style={{
                width: "110px",
                height: "100%",
                alignItems: "center",
                color: "#5E6470", flexDirection: "row", justifyContent: "center"}}>
                <Text style={{fontSize: "17px"}}>{quantity}</Text>
            </View>
            <View style={{width: "150px", height: "100%", alignItems: "center", color: "#5E6470", flexDirection: "row", justifyContent: "flex-end"}}>
                <Text style={{fontSize: "17px"}}>{unitPrice ? `$${unitPrice}` : ""}</Text>
            </View>
            <View style={{height: "100%", flexGrow: 1, alignItems: "center", color: "#5E6470", flexDirection: "row", justifyContent: "flex-end"}}>
                <Text style={{fontSize: "17px"}}>{amount ? `$${amount.toFixed(2)}` : ""}</Text>
            </View>
        </View>
    )
}

export default QuoteDocument