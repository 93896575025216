import { useMemo } from 'react';
import { IItems } from "Types/Interface/IQuotes";

export const useCalculateInvoice = (items: IItems[], discountPercentage: number, discountAmount: number, taxPercentage: number) => {
  return useMemo(() => {
    const lineTotal = items?.map(item => ({
      ...item,
      lineTotal: item?.quantity * item?.rate
    }));

    const subtotal = items?.reduce((total: number, item: IItems) => total + (item.quantity * item.rate), 0);

    const discount = discountPercentage ? (subtotal * (discountPercentage / 100)) : discountAmount;

    const subtotalAfterDiscount = subtotal - discount;

    const tax = subtotalAfterDiscount * (taxPercentage / 100);

    const total = subtotalAfterDiscount + tax;

    return {
      subtotal,
      discount,
      subtotalAfterDiscount,
      tax,
      total,
      itemsLineTotal: lineTotal
    };
  }, [items, discountPercentage, discountAmount, taxPercentage]);
};
