import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './receipt';
import { apiRoutes } from 'Api/apiRoutes';
import { ICheckoutSession, IStripeResponse } from 'Types/Interface/ICheckout';

export const stripeApi = createApi({
    reducerPath: 'stripeApi',
    baseQuery,
    endpoints: (builder) => ({
        createCheckoutSession: builder.mutation<IStripeResponse, { "checkoutSessionDto": ICheckoutSession }>({
            query(body) {
                return {
                    url: apiRoutes.stripe,
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const { useCreateCheckoutSessionMutation } = stripeApi;