import BusinessFormsHeading from "./BusinessFormsHeading";
import { Box, Flex } from "@chakra-ui/react";
import FormikInput from "../Formik/FormikInput";
import FormikTextArea from "../Formik/FormikTextArea";
import { FormType } from "Types/Enums/documentTypeEnums";

type BusinessInvoiceInfoProps = {
    formHeading: string
    currentStep: number
    steps: number
    formType: FormType
}

const BusinessInvoiceInfo = ({ formHeading, currentStep, steps, formType }: BusinessInvoiceInfoProps) => {
    return (
        <Box mb={"64px"} w={'full'}>
            <BusinessFormsHeading title={formHeading} steps={steps} currentStep={currentStep} />
            <Flex gap={"16px"} justifyContent={"space-between"}>
                <FormikInput isRequired flexBasis={"40%"} label={"Invoice Number"} name={`invoiceNumber`} type={"text"} />
                <FormikInput isRequired flexBasis={"30%"} label={"Date"} name={`receiptDate`}
                    type={"date"} />
                {formType === FormType.Quotes && <FormikInput isRequired flexBasis={"30%"} label={"Date Due"} name={"dueDate"} type={"date"} />}

            </Flex>
            <FormikTextArea label={"Note"} name={"note"} />
        </Box>
    )
}

export default BusinessInvoiceInfo