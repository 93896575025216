import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text
} from "@chakra-ui/react";
import { ChangeEvent } from "react"
import { IEarning, IPayStub } from "../../../Types/Interface/IPaystub";
import AdditionalEarnings from "./Forms/AdditionalEarnings";
import CustomDeductions from "./Forms/CustomDeductions";
import { DeductionType, PaymentMode } from "../../../Types/Enums/PaymentModeEnum";
import { useAppDispatch } from "../../../Hooks/useAppDispatch";
import {
  setStateTax,
  setFederalTax,
  setEnableManualTax,
  setPayStubEndDate,
  setPayStubHours,
  setPayStubPayDate,
  setPayStubStartDate
} from "../../../Store/Slices/paystubSlice";

type HandleChange = (e: ChangeEvent) => void
type SetFieldValue = (field: string, value: string | boolean | number | IEarning[] | IEarning) => void

type BaseInputProp = {
  index: number;
  value?: number | string,
  isChecked?: boolean,
  handleChange?: HandleChange,
  isDisabled?: boolean
}

type DateInputProp = BaseInputProp & {
  payDate: string
}


const PeriodicPayStubSection = ({ paystubs, handleChange, setFieldValue }: { paystubs: IPayStub[], handleChange: HandleChange, setFieldValue: SetFieldValue }) => {

  const dispatch = useAppDispatch()

  return (
    <>
      {
        paystubs.map((paystub, index) => (
          <Box key={paystub.id} p={"32px"} mb={'24px'} borderRadius={"8px"} borderWidth={"1px"} borderColor={"#E2E4E5"}>
            <PeriodicPayStubHeader index={index} />
            <Flex gap={"48px"} my={"32px"}>
              <PayDateStartInput value={paystub.from} index={index} />
              <PayDateEndInput value={paystub.to} index={index} handleChange={handleChange} />
            </Flex>
            <Flex gap={"48px"}>
              <PayDateInput value={paystub.payDate} index={index} handleChange={handleChange} isDisabled={!Boolean(paystub.to)} payDate={paystub.to} />
              {
                paystub.earnings[0].type === PaymentMode.Hourly && <TotalHoursSelector value={paystub.earnings[0].hours} mode={paystub.earnings[0].type} index={index} setFieldValue={setFieldValue} />
              }
            </Flex>
            <Flex>
              <FormControl mt={"40px"}>
                <Checkbox color={"#208c55"} onChange={() => dispatch(setEnableManualTax({ setFederalStateTaxManually: !paystub.enableManualTax, payStubIndex: index }))} isChecked={paystub.enableManualTax} name={`employee.isContractor`} fontSize={"18px"}>Edit Tax</Checkbox>
              </FormControl>
            </Flex>
            <Flex gap={"48px"} mt={"15px"}>
              <FormControl>
                <FormLabel color={"#208c55"}>
                  Federal Tax
                </FormLabel>
                <Input value={paystub.deductions.filter(deduction => deduction.type === DeductionType.FederalTax)[0]?.amount ?? ""}
                  onChange={(e) => {
                    const amount = e.target.value.length !== 0 ? Number(e.target.value) : 0

                    dispatch(setFederalTax({ amount, payStubIndex: index }))
                  }}
                  name={`payStubs[${index}].deductions[${index + 1}].FederalTax`}
                  type={"number"}
                  disabled={!paystub.enableManualTax} />
              </FormControl>
              <FormControl>
                <FormLabel color={"#208c55"}>
                  State Tax
                </FormLabel>
                <Input value={paystub.deductions.filter(deduction => deduction.type === DeductionType.StateTax)[0]?.amount ?? ""}
                  onChange={(e) => {
                    dispatch(setStateTax({ amount: Number(e.target.value), payStubIndex: index }))
                  }}
                  name={`payStubs[${index}].earnings[${index + 1}].StateTax`}
                  type={"number"}
                  disabled={!paystub.enableManualTax} />
              </FormControl>
            </Flex>

            <AdditionalEarnings index={index} payStubs={paystubs} setFieldValue={setFieldValue} earnings={paystub.earnings.slice(1)} handleChange={handleChange} />
            <CustomDeductions index={index} deductions={paystub.deductions.slice(4)} />
          </Box>
        ))
      }
    </>
  )
}

const PeriodicPayStubHeader = ({ index }: { index: number }) => {
  return (
    <Flex justifyContent={"space-between"}>
      <Text fontSize={"24px"} fontWeight={"semibold"} color={"#208c55"}>
        PayStub {index + 1}
      </Text>
    </Flex>
  )
}

const PayDateStartInput = ({ value, index }: BaseInputProp) => {
  const dispatch = useAppDispatch()
  return (
    <FormControl>
      <FormLabel>
        Pay Date Start
      </FormLabel>
      <Input value={value}
        onChange={(e) => {
          dispatch(setPayStubStartDate({ date: e.target.value, stubIndex: index }))
        }}
        name={`payStubs[${index}].from`}
        type={"date"} />
    </FormControl>
  )
}

const PayDateEndInput = ({ handleChange, value, index }: BaseInputProp) => {
  const dispatch = useAppDispatch()
  return (
    <FormControl>
      <FormLabel>
        Pay Date End
      </FormLabel>
      <Input
        value={value}
        onChange={(e) => {
          dispatch(setPayStubEndDate({ date: e.target.value, stubIndex: index }))
        }}
        name={`payStubs[${index}].to`}
        type={"date"} />
    </FormControl>
  )
}

const PayDateInput = ({ handleChange, value, index, isDisabled, payDate }: DateInputProp) => {
  const dispatch = useAppDispatch()

  const currentPayDate = new Date(payDate); // Get the current date
  const minPayDate = new Date(currentPayDate); // Clone the date
  minPayDate.setDate(currentPayDate.getDate() + 1); // Add 1 day

  return (
    <FormControl>
      <FormLabel>
        Pay Date
      </FormLabel>
      <Input value={value}
        onChange={(e) => {
          dispatch(setPayStubPayDate({ date: e.target.value, stubIndex: index }))
        }}
        name={`payStubs[${index}].payDate`}
        type={"date"}
        isDisabled={isDisabled}
        min={minPayDate.toISOString().split("T")[0]}
      />
    </FormControl>
  )
}

const TotalHoursSelector = ({ setFieldValue, value, index, mode }: BaseInputProp & { setFieldValue: SetFieldValue, mode: PaymentMode }) => {
  const dispatch = useAppDispatch()
  return (
    <FormControl isRequired>
      <FormLabel>
        Total Hours
      </FormLabel>
      <NumberInput isRequired value={value} isDisabled={mode === PaymentMode.Salary} onChange={(valueString) => dispatch(setPayStubHours({ stubIndex: index, hours: valueString.length > 0 ? parseInt(valueString) : 0 }))}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  )
}

export default PeriodicPayStubSection