import {Text} from "@react-pdf/renderer";
import React from "react";

const PdfWaterMark = ({fontSize} : {fontSize?: string | number | undefined}) => {
    return (
        <Text style={{
            position: 'absolute',
            top: '50%',
            left: '10%',
            transform: 'translate(-50%, -50%) rotate(-30deg)',
            fontSize: fontSize ?? "200px",
            color: 'rgba(0, 0, 0, 0.1)', // Semi-transparent black
            textAlign: 'center',
            zIndex: 10, // Place it behind other content
            fontFamily: "Inter",
            fontWeight: "bold",
            textTransform: "uppercase"
        }}>
            Sample
        </Text>
    )
}

export default PdfWaterMark