import { Box } from "@chakra-ui/react";
import { QuotesTemplate } from "../../Templates/QuotesTemplate";
import { useFormikContext } from "formik";
import { IQuotes } from "../../../Types/Interface/IQuotes";
import { useAppSelector } from "../../../Hooks/useAppSelector";
import FormPreviewLayout from "../../../Layouts/FormPreviewLayout";
import FieldCheckBox from "../../Components/Formik/FieldCheckBox";
import FormPreviewBar from "../../Components/FormPreviewBar";
import { setDocumentType } from "../../../Store/Slices";
import { documentTypeEnums, FormType } from "../../../Types/Enums/documentTypeEnums";
import { useAppDispatch } from "../../../Hooks/useAppDispatch";
import React from "react"

const QuotePreview = () => {
    const { values, handleSubmit } = useFormikContext<IQuotes>()
    const logo = useAppSelector((state) => state.document.logo)
    const dispatch = useAppDispatch()

    return (

        <FormPreviewLayout flexBasis={{ base: "50%", lg: "60%" }}>

            <QuotesTemplate values={values} logoUrl={logo} />

            <Box borderBottom={"1px"} pb={"24px"} mt={"24px"} mb={"24px"} borderBottomColor={"#1fbc6c80"}>
                <FieldCheckBox isChecked={values.agree} name={"agree"} mb={"12px"}> I agree not to use this site for illegal purposes</FieldCheckBox>
                <FieldCheckBox isChecked={values.accept} name={"accept"}> I accept our Term of Use and Privacy Policy</FieldCheckBox>
            </Box>
            <FormPreviewBar submit={handleSubmit} values={values} setDocumentType={() => dispatch(setDocumentType(documentTypeEnums.Quotes))} background={!(values.accept && values.agree) ? "grey" : "primary"} disabled={!(values.accept && values.agree)} formType={FormType.Quotes} />
        </FormPreviewLayout>
    )
}

export default QuotePreview