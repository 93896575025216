import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Image, Input, Text } from "@chakra-ui/react";
import React, { ChangeEvent, useState } from "react";
import { IEarning, IPayStub } from "../../../../Types/Interface/IPaystub";
import { useAppDispatch } from "../../../../Hooks/useAppDispatch";
import {
    addAdditionalEarning, deleteAdditionalEarnings, deleteAdditionEarning,
    editAdditionalEarningDescription,
    setAdditionalEarningsRate, setEarningsHours
} from "../../../../Store/Slices/paystubSlice";
import { AddIcon } from "@chakra-ui/icons";
import trash from "../../../../Assets/Svg/trash.svg";

type HandleChange = (e: ChangeEvent) => void

type SetFieldValue = (field: string, value: number | IEarning[] | IEarning) => void

type AdditionalEarningProps = {
    index: number,
    earnings: IEarning[],
    handleChange: HandleChange,
    setFieldValue: SetFieldValue,
    payStubs: IPayStub[],
}

const AdditionalEarnings = ({ index, earnings, handleChange, }: AdditionalEarningProps) => {

    const [canAddEarnings, setCanAddEarnings] = useState(earnings.length > 0)
    const dispatch = useAppDispatch()

    const addEarning = () => {
        dispatch(addAdditionalEarning())
        setCanAddEarnings(true)
    }

    const removeAllEarnings = () => {
        dispatch(deleteAdditionalEarnings())
        setCanAddEarnings(false)
    }

    return (
        <>
            {
                index === 0 ? (
                    <Flex align={"center"} justifyContent={"space-between"} mt={"60px"} mb={"36px"}>
                        <Box>
                            <Text color={"#208c55"} fontSize={"18px"}>
                                Additional earnings
                            </Text>
                            <Text color={"#0000007F"}>
                                Add Bonuses, Overtime, tips, expenses
                            </Text>
                        </Box>
                        <Checkbox isChecked={canAddEarnings} color={"#208c55"}
                            onChange={() => !canAddEarnings ? addEarning() : removeAllEarnings()}
                            colorScheme={"green"} fontWeight={"normal"} borderColor={"#208c55"}
                            variant={"outline"}>
                            Add Earnings
                        </Checkbox>
                    </Flex>
                ) : (
                    earnings.length > 0 && (
                        <Text color={"#208c55"} fontSize={"18px"} my={"16px"}>
                            Additional earnings
                        </Text>
                    )
                )
            }
            {
                earnings.map((earning, earningIndex) => (
                    <Flex key={earning.id} gap={"8px"} mb={"16px"}>
                        <FormControl>
                            <FormLabel color={"#208c55"}>
                                Description
                            </FormLabel>
                            <Input value={earning.description}
                                disabled={index !== 0}
                                onChange={(e) => {
                                    if (index === 0) {
                                        dispatch(editAdditionalEarningDescription({ description: e.target.value, earningIndex: earningIndex + 1, }))
                                    }
                                }}
                                name={`payStubs[${index}].earnings[${earningIndex + 1}].description`}
                                type={"text"} />
                        </FormControl>
                        <FormControl>
                            <FormLabel color={"#208c55"}>
                                Amount / Rate ($)
                            </FormLabel>
                            <Input value={earning.rate}
                                onChange={(e) => {
                                    const rate = e.target.value.length !== 0 ? parseFloat(e.target.value) : 0
                                    dispatch(setAdditionalEarningsRate({ rate, earningIndex: earningIndex + 1, stubIndex: index }))
                                }}
                                name={`payStubs[${index}].earnings[${earningIndex + 1}].rate`}
                                type={"number"} />
                        </FormControl>
                        <FormControl>
                            <FormLabel color={"#208c55"}>
                                Hours / Quantity
                            </FormLabel>
                            <Input value={earning.hours}
                                onChange={(e) => {
                                    const hours = e.target.value.length !== 0 ? parseFloat(e.target.value) : 0
                                    dispatch(setEarningsHours({ hours, earningIndex: earningIndex + 1, stubIndex: index }))
                                }}
                                name={`payStubs[${index}].earnings[${earningIndex + 1}].hours`}
                                type={"number"} />
                        </FormControl>
                        {
                            index === 0 && <Image cursor={'pointer'} boxSize={'40px'}
                                onClick={() => {
                                    dispatch(deleteAdditionEarning(earningIndex + 1))
                                    earningIndex === 0 && setCanAddEarnings(false)
                                }} src={trash} alignSelf={'flex-end'} />
                        }
                    </Flex>
                ))
            }
            {
                index === 0 && canAddEarnings && (
                    <Flex justifyContent={"end"} mb={"8px"}>
                        <Button
                            onClick={() => addEarning()}
                            _hover={{ bgColor: 'none' }}
                            textAlign={'center'}
                            bgColor={'#20E75B'}
                        >
                            <AddIcon h={'30px'} w={'30px'} p={1} borderRadius={'50%'} border={'2px solid #fff'} color={'#fff'} boxSize={'25px'} />
                        </Button>
                    </Flex>
                )
            }
        </>
    )
}

export default AdditionalEarnings