import { Box, Checkbox, Flex, FormControl, FormLabel, Input, SimpleGrid, Text } from "@chakra-ui/react";
import TextInput from "../../Components/Inputs/TextInput";
import React, { ChangeEvent, ChangeEventHandler, useState } from "react";
import { I1099Nec, IRecipientNec } from "../validator";
import FormikCheckBox from "../../Components/Formik/FormikCheckBox";
import { FormikErrors } from "formik";
import StateDropSelect from "../../Components/StateDropSelect";
import BusinessFormsHeading from "Pages/Components/Forms/BusinessFormsHeading";
import { PatternFormat } from "react-number-format";

type SetValues = (field: string, value: boolean | number | string, shouldValidate?: boolean) => (Promise<FormikErrors<I1099Nec>> | Promise<void>)

const NecRecipientsForm = ({ handleChange, recipients, setValues }: { handleChange: (e: ChangeEvent) => void, recipients: IRecipientNec[], setValues: SetValues }) => {
    return (
        <>
            {
                recipients.map((recipient, index) => <RecipientsForm key={index} index={index} handleChange={handleChange} recipient={recipient} setValues={setValues} />)
            }
        </>
    )
}

const RecipientsForm = ({ index, handleChange, recipient, setValues }: { index: number, handleChange: (e: ChangeEvent) => void, recipient: IRecipientNec, setValues: SetValues }) => {
    const getName = (name: string) => `recipients[${index}].${name}`
    const [showDetails, setShowDetails] = useState(false)
    
    const handleToFixed = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        let value = e.target.value
        let name = e.target.name
        if (value) {
            let toFixedValue = parseFloat(value).toFixed(2)
            setValues(name, toFixedValue)
        }
    }
    return (
        <>
            <BusinessFormsHeading title={"Recipient's' Info"} currentStep={3} steps={3} />
            <Box mb={"56px"}>

                <Flex gap={"42px"}>
                    <TextInput isRequired flexBasis={"60%"} value={recipient.name} label={"Recipient's Name"} onChange={handleChange} name={getName('name')} />
                    <FormControl isRequired mt={"20px"} flexBasis={"40%"}>
                        <FormLabel>
                            {"Recipient's Tin(Social security)"}
                        </FormLabel>
                        <PatternFormat
                            focusBorderColor="primary"
                            customInput={Input}
                            value={recipient.ssn}
                            format={"###-##-####"}
                            onChange={handleChange}
                            name={getName('ssn')}
                            type={"text"}
                            placeholder={"Ein / Tax Id"} />
                    </FormControl>
                </Flex>
                <Flex gap={"42px"}>
                    <TextInput isRequired flexBasis={"60%"} value={recipient.address.stateAddress} label={"Address"} onChange={handleChange} name={getName('address.stateAddress')} />
                </Flex>
                <Flex gap={"42px"}>
                    <TextInput isRequired flexBasis={"40%"} value={recipient.address.city} label={"City"} onChange={handleChange} name={getName('address.city')} />
                    <StateDropSelect isRequired flexBasis={"40%"} name={getName("address.state")} value={recipient.address.state} label={"State"} onChange={handleChange} notFormik={true} />
                    <TextInput isRequired flexBasis={"30%"} value={recipient.address.zipcode} label={"Zip Code"} onChange={handleChange} name={getName("address.zipcode")} />
                </Flex>
                <Flex gap={"42px"} mt={"10px"}>
                        <TextInput justifyContent={"flex-start"} flexBasis={"40%"} value={recipient.options.nonEmployeeCompensation ?? 0} onChange={handleChange} onBlur={handleToFixed}
                            label={"01 Non employee compensation"} name={getName("options.nonEmployeeCompensation")} type={"number"} />
                  
                        <Checkbox  isChecked={showDetails} justifyContent={"flex-end"} flexBasis={"60%"} onChange={() => setShowDetails((prevState) => !prevState)}>
                        Show Additional options
                    </Checkbox>
                </Flex>
                {
                    showDetails && (
                        <>
                            <Text textTransform={"uppercase"} fontSize={"20px"} color={"primary"} borderBottom={"1px"} w={"80%"} mt={"24px"} borderBottomColor={"primary"}>
                                Recipient&apos;s Options
                            </Text>
                            <SimpleGrid columns={{ base: 2, md: 3 }} gap={"6"}>
                                <Box>
                                    <FormikCheckBox isChecked={recipient.options.directSales ?? false}
                                        onChange={() => setValues(getName('options.directSales'), !recipient.options.directSales)}
                                        label={"02 Payer made direct sales totaling $5000\n" +
                                            "or more of consumer products to recipient for resale"}
                                        name={getName('options.directSales')} />
                                </Box>

                                <Box>
                                    <TextInput flexBasis={"40%"} value={recipient.options.income ?? 0} label={"03 "} onChange={handleChange} onBlur={handleToFixed} name={getName("options.income")} type={"number"} />
                                </Box>
                                <Box>
                                    <TextInput flexBasis={"45%"} value={recipient.options.incomeTax ?? 0} label={"04 Federal Income Tax withheld"} onChange={handleChange} onBlur={handleToFixed} name={getName("options.incomeTax")} type={"number"} />
                                </Box>
                                <Box>
                                    <TextInput flexBasis={"10%"} value={recipient.options.accountNumber ?? ""} label={"Account Number"} onChange={handleChange} name={getName("options.accountNumber")} type={"text"} />
                                </Box>
                                <Box>
                                    <TextInput flexBasis={"40%"} value={recipient.options.stateTax ?? 0} label={"05 State tax withheld"} onChange={handleChange} onBlur={handleToFixed} name={getName("options.stateTax")} type={"number"} />
                                </Box>
                                <Box>
                                    <TextInput flexBasis={"40%"} value={recipient.options.payersNo ?? ""} label={"06 State / Payer's no"} onChange={handleChange} name={getName("options.payersNo")} type={"text"} />
                                </Box>
                                <Box>
                                    <TextInput flexBasis={"40%"} value={recipient.options.stateIncome ?? 0} label={"07 State income"} onChange={handleChange} onBlur={handleToFixed} name={getName("options.stateIncome")} type={"number"} />
                                </Box>
                            </SimpleGrid>
                        </>
                    )
                }
            </Box>
        </>
    )
}

export default NecRecipientsForm