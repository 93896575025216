import { Flex } from "@chakra-ui/react";
import AppLayout from "../../Layouts/AppLayout";
import PayStubForms from "./Components/PayStubForms";
import PayStubPreviewSection from "./Components/PayStubPreviewSection";
import React, {useEffect, useState} from "react"
import { useAppSelector } from "../../Hooks/useAppSelector";
import {initialPaystubsState, loadPaystub, resetPayStubs, selectPaystubData} from "../../Store/Slices/paystubSlice";
import { useFormik } from "formik";
import useHandleUploadLogo from "../../Hooks/useHandleUploadLogo";
import {useAppDispatch} from "../../Hooks/useAppDispatch";
import {IPaystubData} from "../../Types/Interface/IPaystub";
import {setDocumentCount, setDocumentType, setFormType} from "../../Store/Slices";
import {documentTypeEnums, FormType} from "../../Types/Enums/documentTypeEnums";
import {AppRoutes} from "../../Routes/AppRoutes";
import {useNavigate} from "react-router-dom";

const PayStub = () => {
    const navigate = useNavigate();
    const data = useAppSelector(selectPaystubData)
    const dispatch = useAppDispatch()
    const [logo, setLogo] = useState("")
    const { downloaded } = data
    const { handleChange, handleSubmit, values, setFieldValue, } = useFormik({
        initialValues: downloaded ? initialPaystubsState : data ,
        onSubmit: (values, formikHelpers) => {
            formikHelpers.setSubmitting(true)
            dispatch(setDocumentType(documentTypeEnums.Paystub))
            dispatch(setDocumentCount(data.info.payStubCount))
            dispatch(setFormType(FormType.Paystub))
            formikHelpers.setSubmitting(false)
            navigate(AppRoutes.checkout);
        }
    })

    const handleLogoUpload = useHandleUploadLogo({
        companyName: values.company.name, companyEmail: values.company.zipcode, setLogoUrl: (logo) => {
            setLogo(logo)
            setFieldValue("company.logo", logo)
        }
    })

    useEffect(() => {
        if (downloaded) {
            dispatch(resetPayStubs())
        }
    }, [downloaded])

    useEffect(() => {
        const employee = values.employee
        const company = values.company
        const editedData: IPaystubData = {
            ...data, company, employee
        }
        dispatch(loadPaystub(editedData))
    }, [values.employee, values.company]);



    return (
        <AppLayout isForm>
            {/*  Forms and Preview*/}
            <form onSubmit={handleSubmit}>
                <Flex w={"full"} px={"50px"} py={"50px"} gap={"64px"} pos={"relative"}>
                    <PayStubForms handleChange={handleChange} values={data} setFieldValue={setFieldValue} handleLogo={handleLogoUpload}/>
                    <PayStubPreviewSection data={data} logo={logo} submit={handleSubmit} />
                </Flex>
            </form>
        </AppLayout>
    )
}


export default PayStub