import {Document, Page, View, Image, Text, PDFDownloadLink} from "@react-pdf/renderer";
import React, {useEffect, useState} from "react"
import '@fontsource-variable/inter'
import {formatAddress, us_LocaleDateString} from "../Services/utils";
import {useCalculateInvoice} from "../Hooks";
import {useAppSelector} from "../Hooks/useAppSelector";
import {IReceipt} from "../Types/Interface/IReceipt";
import AppDocumentViewer from "../Components/AppDocumentViewer";
import {Button, Center} from "@chakra-ui/react";
import PdfWaterMark from "../Components/PdfWaterMark";
import {setDocumentDownloaded} from "../Store/Slices";
import {useAppDispatch} from "../Hooks/useAppDispatch";
import {IDocumentCheckout} from "../Types/Interface/IRequestBody";
import {reqClient} from "../Api/reqClient";

const checkOutReceipt = async (session: IDocumentCheckout, data: IReceipt): Promise<boolean> => {
    const {checkoutMode, sessionId, amount} = session
    const url = `/receipts/${sessionId}/${amount}/${checkoutMode}`
    const body = {
        taxAmount: data.taxAmount,
        discountAmount: data.discountAmount,
        discountPercentage: data.discountPercentage,
        invoiceNumber: data.invoiceNumber,
        note: data.note,
        dueDate: data.dueDate,
        receiptDate: data.receiptDate,
        from: {
            ...data.from,
            isCompletedForm: true,
            address: {
                ...data.from.address,
                isCompletedForm: true,
                country: "United state"
            },
            ein: "",
            taxId: "",
            isContractor: true
        },
        for: {
            ...data.for,
            isCompletedForm: true,
            address: {
                ...data.for.address,
                isCompletedForm: true,
                country: "United state"
            },
            ein: "",
            taxId: "",
            isContractor: true
        },
        lineItems: data.lineItems.map((lineItem) => ({...lineItem, isCompletedForm: true})),
        quoteHtmlString: "",
        email: data.from.email
    }
    const response = await reqClient.post(url, body)
    const resData = response.data
    return resData.isSuccess ?? false
}

const ReceiptDocument = ({hasPaid, session}: { hasPaid?: boolean, session?: IDocumentCheckout }) => {
    const document: IReceipt | null = useAppSelector((state) => state.document.documentData) as IReceipt;
    const dispatch = useAppDispatch()
    const logo = useAppSelector((state) => state.document.logo)
    const [checkoutLoading, setCheckOutLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false)

    const setDownloadedFlag = () => {
        dispatch(setDocumentDownloaded())
    }

    useEffect(() => {
        if (hasPaid && session) {
            setCheckOutLoading(true);
            checkOutReceipt(session, document).then((res) => {
                setIsSuccess(res)
            }).catch(() => {

            }).finally(() => {
                setCheckOutLoading(false)
            })
        }
    }, [session, hasPaid])

    if (hasPaid) {
        return (
            <>
                {
                    checkoutLoading ? (
                        <Button>
                            Verifying payment...
                        </Button>
                    ) : (
                        isSuccess ? (
                            <Button>
                                <PDFDownloadLink onClick={setDownloadedFlag}
                                                 document={<Receipt document={document} logo={logo} hasPaid={hasPaid}/>}
                                                 fileName={"receipt.pdf"}>
                                    <div>
                                        Download Receipt
                                    </div>
                                </PDFDownloadLink>
                            </Button>
                        ) : <Center>An error occurred, could not verify payment</Center>
                    )
                }
            </>
        )
    }

    return (
        <AppDocumentViewer>
            <Receipt document={document} logo={logo}/>
        </AppDocumentViewer>
    )
}


const Receipt = ({document, logo, hasPaid}: { document: IReceipt, logo: string, hasPaid?: boolean}) => {
    const {
        addressLine1: companyAddress,
        city: companyCity,
        regionOrState: companyState,
        postalCode: companyZip
    } = document.from.address

    const {subtotal, discount, itemsLineTotal, tax, total} = useCalculateInvoice(
        document?.lineItems ?? [],
        document?.discountPercentage ?? 0,
        document?.discountAmount ?? 0,
        document?.taxAmount ?? 0
    );
    return (
        <Document>
            <Page size={[999, 1413]} style={{flexDirection: "column",}}>
                {
                    !hasPaid && <PdfWaterMark/>
                }
                <View style={{
                    width: "100%",
                    minHeight: "224.98px",
                    backgroundColor: "#F9FAFC",
                    borderBottom: "1px solid #EBEDF2",
                    padding: "18px 48px",
                    fontFamily: 'Inter',
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <View>
                        <View style={{height: "120px", width: "120px"}}>
                            <View style={{marginTop: "30px", height: "82px", width: "82px", marginRight: "22px"}}>
                                {
                                    logo && (
                                        <Image src={logo} source={"logo"}/>
                                    )
                                }
                            </View>
                        </View>
                        <Text style={{
                            fontSize: "29px",
                            fontFamily: 'Inter',
                            color: "#4C63ED",
                            fontWeight: "bold",
                            marginBottom: "36px",
                            textTransform: "uppercase"
                        }}>
                            {document.from.name}
                        </Text>
                        <Text style={{color: "#5E6470", fontSize: "17px", paddingBottom: "8px"}}>{companyAddress}</Text>
                        <Text style={{color: "#5E6470", fontSize: "17px", paddingBottom: "8px"}}>
                            {formatAddress(companyCity)} {companyState} {companyZip.trim()}
                        </Text>
                        <Text style={{color: "#5E6470", fontSize: "17px", paddingBottom: "8px"}}>{document.from.email}</Text>
                        <Text style={{color: "#5E6470", fontSize: "17px"}}>{document.from.phoneNumber}</Text>
                    </View>

                    <Text style={{fontSize: "47px", textTransform: "uppercase", fontWeight: "semibold"}}>
                        Receipt
                    </Text>
                </View>
                <View style={{flexGrow: 1, width: "100%", padding: "24px 48px", fontFamily: 'Inter'}}>
                    <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                        <View style={{}}>
                            <Text style={{fontWeight: "semibold", fontSize: "17px", marginBottom: "12px"}}>
                                Customer&apos;s Details
                            </Text>
                            <View style={{
                                flexDirection: "row",
                                width: "317px",
                                textAlign: "left",
                                justifyContent: "flex-start",
                                color: "#5E6470"
                            }}>
                                <Text>
                                    {document.for.name}
                                </Text>
                            </View>
                            <View style={{
                                flexDirection: "row",
                                width: "317px",
                                justifyContent: "space-between",
                                color: "#5E6470",
                                margin: "6px 0px"
                            }}>

                                <View style={{
                                    width: "317px",
                                    textAlign: "left",
                                    justifyContent: "flex-start",
                                }}>
                                    <Text>{document.for.address.addressLine1}</Text>
                                    <Text>{formatAddress(document.for.address.city)} {document.for.address.regionOrState.trim()} {document.for.address.postalCode}</Text>
                                </View>
                            </View>
                            <View style={{
                                flexDirection: "row",
                                width: "317px",
                                textAlign: "left",
                                justifyContent: "flex-start",
                                color: "#5E6470"
                            }}>

                                <Text>
                                    {document.for.phoneNumber}
                                </Text>
                            </View>
                        </View>
                        <View>
                            <Text style={{fontWeight: "semibold", fontSize: "17px", marginBottom: "12px"}}>
                                Receipt Details
                            </Text>
                            <View style={{
                                flexDirection: "row",
                                width: "222px",
                                justifyContent: "space-between",
                                color: "#5E6470"
                            }}>
                                <Text style={{fontWeight: "bold", fontSize: "17px"}}>
                                    Date
                                </Text>
                                <Text>
                                    {us_LocaleDateString(document.receiptDate)}
                                </Text>
                            </View>
                            <View style={{
                                flexDirection: "row",
                                width: "222px",
                                justifyContent: "space-between",
                                color: "#5E6470",
                                margin: "6px 0px"
                            }}>
                                <Text style={{fontWeight: "bold", fontSize: "17px",}}>
                                    Invoice
                                </Text>
                                <Text>
                                    {document.invoiceNumber}
                                </Text>
                            </View>
                            {/* <View style={{
                                flexDirection: "row",
                                width: "222px",
                                justifyContent: "space-between",
                                color: "#5E6470"
                            }}>
                                <Text style={{fontWeight: "bold", fontSize: "17px"}}>
                                    Date Due
                                </Text>
                                <Text>
                                    {us_LocaleDateString(document.dueDate)}
                                </Text>
                            </View> */}
                        </View>
                    </View>
                    <View style={{marginTop: "64px", flexGrow: 1}}>
                        <View style={{
                            flexDirection: "row",
                            border: "1px solid black",
                            backgroundColor: "#F9FAFC",
                            height: "42px",
                            width: "100%"
                        }}>
                            <View style={{
                                width: "167px",
                                borderRight: "1px solid black",
                                height: "100%",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                <Text style={{fontWeight: "bold"}}>Quantity</Text>
                            </View>
                            <View style={{
                                width: "377px",
                                borderRight: "1px solid black",
                                height: "100%",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                <Text style={{fontWeight: "bold"}}>Item Description</Text>
                            </View>
                            <View style={{
                                width: "167px",
                                borderRight: "1px solid black",
                                height: "100%",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                <Text style={{fontWeight: "bold"}}>Unit Price</Text>
                            </View>
                            <View style={{
                                height: "100%",
                                flexGrow: 1,
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}>
                                <Text style={{fontWeight: "bold"}}>Amount</Text>
                            </View>
                        </View>
                        {
                            document.lineItems.map((lineItem, index) => <LineItem key={index} quantity={lineItem.quantity}
                                                                                  description={lineItem.description}
                                                                                  unitPrice={lineItem.rate}
                                                                                  amount={itemsLineTotal[index].lineTotal}/>)
                        }

                    </View>
                    {
                        document.note && (
                            <View style={{flexDirection: "row"}}>
                                <Text style={{color: "#1A1C21",  fontWeight: "semibold"}}>
                                    Note:
                                </Text>
                                <Text style={{color: "#1A1C21", marginLeft: "8px"}}>
                                    {document.note}
                                </Text>
                            </View>
                        )
                    }
                    <Text style={{color: "#1A1C21", marginTop: "28px", fontWeight: "semibold"}}>
                        Thanks for the business
                    </Text>
                </View>

                <View fixed style={{
                    width: "100%",
                    height: "246px",
                    backgroundColor: "#F9FAFC",
                    fontFamily: "Inter",
                    fontSize: "17px",
                    padding: "42px 48px",
                    justifyContent: "space-between"
                }}>
                    <View>
                        <View style={{justifyContent: "space-between", flexDirection: "row"}}>
                            <Text>Subtotal</Text>
                            <Text style={{width: "150px"}}>${subtotal?.toFixed(2)}</Text>
                        </View>
                        <View style={{justifyContent: "space-between", margin: "16px 0px", flexDirection: "row",}}>
                            <Text>Discount</Text>
                            <Text style={{width: "150px"}}>${discount.toFixed(2)}</Text>
                        </View>
                        <View style={{justifyContent: "space-between", flexDirection: "row"}}>
                            <Text>Sales Tax</Text>
                            <Text style={{width: "150px"}}>${tax.toFixed(2)}</Text>
                        </View>
                    </View>
                    <View style={{justifyContent: "space-between", flexDirection: "row"}}>
                        <Text style={{color: "#4C63ED", fontWeight: "bold"}}>Total</Text>
                        <Text style={{color: "#4C63ED", fontWeight: "bold", width: "150px"}}>US$ {total.toFixed()}</Text>
                    </View>
                </View>
            </Page>
        </Document>)
}


const LineItem = ({quantity, amount, description, unitPrice}: {
    quantity?: number,
    description?: string,
    unitPrice?: number,
    amount?: number,
}) => {
    return (
        <View style={{
            flexDirection: "row",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            borderBottom: "1px solid black",
            height: "42px",
            width: "100%"
        }}>
            <View style={{
                width: "167px",
                borderRight: "1px solid black",
                height: "100%",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center"
            }}>
                <Text style={{fontSize: "17px"}}>{quantity}</Text>
            </View>
            <View style={{
                width: "377px",
                borderRight: "1px solid black",
                height: "100%",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center"
            }}>
                <Text style={{fontSize: "17px"}}>{description}</Text>
            </View>
            <View style={{
                width: "167px",
                borderRight: "1px solid black",
                height: "100%",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center"
            }}>
                <Text style={{fontSize: "17px"}}>{unitPrice ? `$${unitPrice}` : ""}</Text>
            </View>
            <View style={{
                height: "100%",
                flexGrow: 1,
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center"
            }}>
                <Text style={{fontSize: "17px"}}>{amount ? `$${amount.toFixed(2)}` : ""}</Text>
            </View>
        </View>
    )
}

export default ReceiptDocument