import { Box, Checkbox, Flex } from "@chakra-ui/react";
import FormikInput from "../Formik/FormikInput";
import FormikFileInput from "../Formik/FormikFileInput";
import { setLogo } from "../../../Store/Slices";
import { useAppDispatch } from "../../../Hooks/useAppDispatch";
import BusinessFormsHeading from "./BusinessFormsHeading";
import StateDropSelect from "../StateDropSelect";
import React, { useState } from "react"

type BusinessInfoProps = {
    formHeading: string;
    steps: number;
    currentStep: number;
    prefix: string;
    addressPrefix: string;
    hasWebsiteField?: boolean;
    handleLogoUpload?: (image: File) => void;
}

const BusinessInfo = ({ prefix, addressPrefix, steps, formHeading, currentStep, handleLogoUpload, hasWebsiteField }: BusinessInfoProps) => {
    const dispatch = useAppDispatch()

    return (
        <Box mb={"64px"} w={'full'}>
            <BusinessFormsHeading title={formHeading} currentStep={currentStep} steps={steps} />
            <Flex gap={"16px"} justifyContent={"space-between"} w={"100%"}>
                <FormikInput isRequired flexBasis={"70%"} label={"Name"} name={`${prefix}name`} type={"text"} />
                <FormikInput isRequired flexBasis={"30%"} label={"Email"} name={`${prefix}email`} type={"text"} />
            </Flex>
            <Flex gap={"16px"} justifyContent={"space-between"} w={"100%"}>
                <FormikInput isRequired flexBasis={"50%"} label={"Business Address"} name={`${addressPrefix}addressLine1`}
                    type={"text"} />
            </Flex>
            <Flex gap={"16px"} align={"end"} justifyContent={"space-between"}>
                <FormikInput isRequired flexBasis={"35%"} label={"City"} name={`${addressPrefix}city`} type={"text"} />
                <StateDropSelect isRequired flexBasis={"30%"} label={"State"} name={`${addressPrefix}regionOrState`} type={"text"} />
                <FormikInput isRequired flexBasis={"35%"} label={"Zip Code"} name={`${addressPrefix}postalCode`} type={"text"} />
            </Flex>
            <Flex gap={"16px"} justifyContent={"space-between"} align={"end"}>
                <Flex >
                    <FormikInput isRequired label={"Phone Number"} maxWidth={"100%"} name={`${prefix}phoneNumber`} type={"text"} maxLength={15} />
                </Flex>
                {
                    hasWebsiteField && (
                        <FormikInput flexBasis={"80%"} label={"WebsiteLink (Optional)"} name={`${prefix}websiteUrl`} type={"text"} />
                    )
                }
            </Flex>
            {
                handleLogoUpload && (
                    <FormikFileInput flexBasis={"80%"}
                        setImage={(image) => {
                            if (typeof image === "string") {
                                dispatch(setLogo(image))
                            }
                        }}
                        handleFileUpload={handleLogoUpload}
                        label={""}
                        name={`${prefix}logo`}
                        type={"file"}
                        accept={"image/*"}
                    />
                )
            }
        </Box>
    )
}

export default BusinessInfo