import {Box, Button, Checkbox, Flex} from "@chakra-ui/react"
import React, {useState} from "react"
import {IPaystubData} from "../../../Types/Interface/IPaystub";
import StubTemplate from "../../Templates/StubTemplate";
import {setDocumentType} from "../../../Store/Slices";
import {documentTypeEnums, FormType} from "../../../Types/Enums/documentTypeEnums";
import FormPreviewBar from "../../Components/FormPreviewBar";
import {useAppDispatch} from "../../../Hooks/useAppDispatch";

const PayStubPreviewSection = ({data, submit}: {data: IPaystubData, logo: string, submit?: () => void}) => {
    const [currentPayStub, setCurrentPayStub] = useState(0)
    const [agree, setAgree] = useState(false)
    const [accept, setAccept] = useState(false)
    const dispatch = useAppDispatch()


  return (
      <Box pos={"sticky"} pt={"32px"} h={"max-content"} flexBasis={"50%"} top={"40px"}>
          <Box w={"full"} mb={"32px"}>
              <StubTemplate paystub={data.payStubs[currentPayStub]} company={data.company} employee={data.employee}/>
          </Box>
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={"16px"}>
              <Button onClick={() => setCurrentPayStub(prev => prev - 1)} disabled={currentPayStub === 0}>
                  prev
              </Button>
             <Flex>
                 <Box pr={"8px"}>
                     {
                         currentPayStub + 1
                     }
                 </Box>
                 of {data.info.payStubCount}
             </Flex>
              <Button onClick={() => setCurrentPayStub(prev => prev + 1)} disabled={currentPayStub + 1 === data.info.payStubCount}>
                  Next
              </Button>
          </Flex>
          <Box borderBottom={"1px"} pb={"24px"}  borderBottomColor={"#00000080"}>
              <Checkbox isChecked={agree} onChange={() => setAgree(!agree)} mb={"12px"}> I agree not to use this site for illegal purposes</Checkbox>
              <Checkbox isChecked={accept} onChange={() => setAccept(!accept)}> I accept our Term of Use and Privacy Policy</Checkbox>
          </Box>
          <FormPreviewBar submit={submit} setDocumentType={()=> dispatch(setDocumentType(documentTypeEnums.Paystub))} background={!(accept && agree) ? "grey" : "primary"} disabled={!(accept && agree)} formType={FormType.Paystub}/>
      </Box>
  )
}

export default PayStubPreviewSection