import TagManager from 'react-gtm-module'
import { getAppEnv } from 'Services/utils'

const gtmId = (getAppEnv() === 'production' ? process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID_PROD : process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID_DEV) ?? ""


const tagManagerArgs = {
    gtmId
}

export const InitializeGTM = () => {
    TagManager.initialize(tagManagerArgs)
}

