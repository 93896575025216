import {DeductionType} from "../Types/Enums/PaymentModeEnum";
import {IPayStub} from "../Types/Interface/IPaystub";

const useStubDetails = (paystub: IPayStub) => {
    const overtimes = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("overtime"))
    const holidays = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("holiday"))
    const vacations = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("vacation"))
    const bonuses = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("bonus"))
    const tips = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("tips"))
    const medicare = paystub.deductions.find((deduction) => deduction.type === DeductionType.Medicare)
    const socialSecurity = paystub.deductions.find((deduction) => deduction.type === DeductionType.SocialSecurity)
    const incomeFedTax = paystub.deductions.find((deduction) => deduction.type === DeductionType.FederalTax)
    const stateTax = paystub.deductions.find((deduction) => deduction.type === DeductionType.StateTax)
    const additionalDeductions = paystub.deductions.filter((deduction) => deduction.type === DeductionType.AdditionalDeduction)
    const additionalEarnings = paystub.earnings.slice(1).filter((earning) => {
        return !overtimes.includes(earning) && !holidays.includes(earning)
            && !vacations.includes(earning) && !bonuses.includes(earning)
            && !tips.includes(earning)
    })
  return {
      overtimes, holidays,
      stateTax, additionalDeductions, additionalEarnings,
      vacations, bonuses, tips, medicare, socialSecurity, incomeFedTax
  }
}

export default useStubDetails