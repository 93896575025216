import { memo, PropsWithChildren } from "react";
import { useField } from "formik";
import { Checkbox, CheckboxProps } from "@chakra-ui/react";

const FieldCheckBox = memo(
    function ({ children, name, ...props }: PropsWithChildren<CheckboxProps>) {
        const [field, meta] = useField(`${name}`);

        return (
            <div>
                <Checkbox {...field} colorScheme='green' {...props}>
                    {children}
                </Checkbox>
                {meta.touched &&
                    meta.error && <div className="error">{meta.error}</div>}
            </div>
        )
    }
)

export default FieldCheckBox