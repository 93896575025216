import AppLayout from "../../Layouts/AppLayout";
import { Flex } from "@chakra-ui/react";
import { useAppSelector } from "../../Hooks/useAppSelector";
import W2Forms from "./Components/W2Forms";
import W2Preview from "./Components/W2Preview";
import React, {FormEvent, useEffect} from "react";
import {resetW2, selectW2} from "../../Store/Slices/w2Slice";
import { useToast } from "../../Hooks/useToast";
import { AppRoutes } from "../../Routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import { setDocumentCount, setDocumentType, setFormType} from "../../Store/Slices";
import {documentTypeEnums, FormType} from "../../Types/Enums/documentTypeEnums";
import { useAppDispatch } from "../../Hooks/useAppDispatch";
const W2 = () => {
    const { accept, agree, downloaded } = useAppSelector(selectW2)
    const navigate = useNavigate();
    const { alert } = useToast()
    const dispatch = useAppDispatch()

    useEffect(() => {
        // resets form state once user downloaded data already
        if (downloaded){
            dispatch(resetW2())
        }
    }, [downloaded])

    const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        dispatch(setDocumentType(documentTypeEnums.W2))
        if (!agree || !accept) {
            alert("error", "Accept our TOS and agree to not use it for illegal activities")
        } else {
            dispatch(setDocumentType(documentTypeEnums.W2))
            dispatch(setDocumentCount(1))
            dispatch(setFormType(FormType.W2))
            navigate(AppRoutes.checkout);
        }
    }
    return (
        <AppLayout isForm>
            <form onSubmit={handleSubmit}>
                <Flex w={"full"} direction={{ base: "column", md: "row" }} px={"50px"} py={"50px"}
                    gap={"32px"} pos={"relative"}>
                    <W2Forms />
                    <W2Preview submit={handleSubmit} />
                </Flex>
            </form>
        </AppLayout>
    )
}

export default W2