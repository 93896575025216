import {Box, Button, Center} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../Hooks/useAppSelector";
import {selectW2, setW2Downloaded} from "../Store/Slices/w2Slice";
import useFillW2, {transformW2Data} from "../Hooks/useFillW2";
import PdfViewer from "../Pages/Components/PdfViewer";
import {IDocumentCheckout} from "../Types/Interface/IRequestBody";
import IW2 from "../Types/Interface/IW2";
import {reqClient} from "../Api/reqClient";
import {useAppDispatch} from "../Hooks/useAppDispatch";

const checkOutW2 = async (session: IDocumentCheckout, data: IW2)=>{
    const {checkoutMode, sessionId, amount} = session
    const url = `/W2/${sessionId}/${amount}/${checkoutMode}`
    const transformedData = transformW2Data(data)
    const body = {
        w2Dto: {
            ...transformedData.dto,
            isCompletedForm: true,
            employerInfo: {...transformedData.dto.employerInfo, isCompletedForm: true},
            w2Employees: [
                {
                    ...transformedData.dto.employee,
                    tax: {...transformedData.dto.employee.tax, isCompletedForm: true},
                }
            ]
        }
    }

    const response = await reqClient.post(url, body)
    const resData = response.data
    return resData.isSuccess ?? false
}

const W2Document = ({hasPaid, session}: {hasPaid?: boolean, session?: IDocumentCheckout}) => {
    const data = useAppSelector(selectW2)
    const { data: w2Pdf, refreshData, loading } = useFillW2(data)
    const anchorRef = useRef<HTMLAnchorElement>(null)
    const [checkoutLoading, setCheckOutLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false)
    const dispatch = useAppDispatch()


    useEffect(()=>{
        if (hasPaid && session){
            setCheckOutLoading(true);
            checkOutW2(session, data).then((res)=>{
                setIsSuccess(res)
            }).catch(()=>{

            }).finally(()=>{
                setCheckOutLoading(false)
            })
        }
    }, [session, hasPaid])

    const downloadPdf = () => {
        const pdfUrl = URL.createObjectURL(w2Pdf as Blob)
        if (anchorRef.current) {
            anchorRef.current.href = pdfUrl
            anchorRef.current.download = "w2"
            anchorRef.current.click()
        }
        dispatch(setW2Downloaded())
    }


    if (hasPaid && session) {
        return (
            <>
                {
                    checkoutLoading ? (
                        <Button>
                            Verifying payment...
                        </Button>
                    ) : (
                        isSuccess ? (
                            <>
                                <Box position="absolute" top="-9999px" left="-9999px">
                                    <a href={'#'} ref={anchorRef}>Download file</a>
                                </Box>
                                <Button onClick={downloadPdf}>
                                    Download W2
                                </Button>
                            </>
                        ) : <Center>An error occurred, could not verify payment</Center>
                    )
                }
            </>
        )
    }

    return (
        <PdfViewer isPreview src={w2Pdf} documentLoading={loading} onRefresh={() => { refreshData() }}></PdfViewer>
    )
}


export default W2Document