import {
    Box,
    Button,
    Center,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import { DocumentMap, FormType } from "../../Types/Enums/documentTypeEnums";
import React, { useState } from "react";
import { Document } from "Store/Slices";

const FormPreviewBar = ({ background, disabled, setDocumentType, formType, submit }: { background: string, disabled: boolean, setDocumentType: () => void, values?: Document, formType?: FormType, submit?: () => void }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [preview, setPreview] = useState(false)

    return (
        <>
            <Modal isOpen={isOpen} size={"3xl"} onClose={onClose} useInert={false}>
                <ModalOverlay />
                <ModalContent height={"95%"} margin={2} >
                    <ModalHeader>{formType}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box height={"100%"}>
                            {preview && formType ? DocumentMap({ formType }) : "Form not available"}
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button mr={3} onClick={() => {
                            setPreview(false)
                            onClose()
                        }}>
                            Close
                        </Button>
                        <Button type={"submit"} onClick={submit} background={background}>
                            Checkout
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Center>
                <Flex direction={"column"} align={"center"}>
                    <Flex gap={"24px"} mt={"24px"}>
                        <Button type={"submit"} disabled={disabled} background={background}>
                            Checkout
                        </Button>
                        <Button variant={"outline"} onClick={() => {
                            onOpen()
                            setDocumentType()
                            setPreview(true)
                        }}>
                            Preview
                        </Button>
                    </Flex>
                </Flex>
            </Center>
            <Box h={"20"}>
            </Box>
        </>
    )
}

export default FormPreviewBar