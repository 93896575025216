import { IPaystubData } from "../../Types/Interface/IPaystub";
import {
    DeductionType,
    MaritalStatus,
    PaymentMode,
    paymentScheduleInfo,
    PaySchedule
} from "../../Types/Enums/PaymentModeEnum";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import {
    calculateEarningsYtd,
    calculateInitialYtd,
    calculateNetPay,
    generateDeduction,
    generateEarning,
    generatePayStub,
    getFIT,
    getMedicare,
    getPayPeriods,
    getSocialSecurity,
    modifyPayStubs,
    resizePaystubs
} from "../../Services/payStubUtils";
import updatePaymentMode from "./paystubReducers/updatePaymentMode";
import { addDays, formatDate } from "date-fns";
import { currentYearFirstDay, toDecimalPlaces } from "Services/utils";

const initialInfo = {
    paymentMode: PaymentMode.Hourly,
    paySchedule: PaySchedule.Weekly,
    payStubCount: 1,
    hourlyRate: 20,
    salary: 48000,
    taxState: "",
}

const defaultTotal = 20 * paymentScheduleInfo.Weekly.hours
const defaultPayPeriods = getPayPeriods(PaySchedule.Weekly)

// calculates the total federal income tax for the total year
const defaultTotalFit = getFIT(defaultTotal, PaySchedule.Weekly, MaritalStatus.Single)
// value for federal income tax for current paystub
const defaultPayStubFit = defaultTotalFit / defaultPayPeriods

const defaultTotalSocialTax = getSocialSecurity(defaultTotal, PaySchedule.Weekly)
const defaultSocialTax = defaultTotalSocialTax / defaultPayPeriods


const defaultTotalMedicare = getMedicare(defaultTotal, PaySchedule.Weekly)
const defaultMedicare = defaultTotalMedicare / defaultPayPeriods


const initialEarnings = [
    generateEarning(
        {
            description: "Regular",
            rate: 20,
            type: PaymentMode.Hourly,
            hours: paymentScheduleInfo.Weekly.hours,
            total: defaultTotal,
            ytd: calculateEarningsYtd(
                calculateInitialYtd(PaySchedule.Weekly, defaultTotal, new Date()), defaultTotal)
        }
    )
]


const initialDeductions = [
    generateDeduction({
        description: "Federal Tax",
        type: DeductionType.FederalTax,
        amount: defaultPayStubFit,
        ytd: calculateInitialYtd(PaySchedule.Weekly, defaultPayStubFit, new Date()) + defaultPayStubFit,
    }),
    generateDeduction({
        description: "FICA - Social Security",
        type: DeductionType.SocialSecurity,
        amount: defaultSocialTax,
        ytd: calculateInitialYtd(PaySchedule.Weekly, defaultSocialTax, new Date()) + defaultSocialTax,
    }),
    generateDeduction({
        description: "FICA - Medicare",
        type: DeductionType.Medicare,
        amount: defaultMedicare,
        ytd: calculateInitialYtd(PaySchedule.Weekly, defaultMedicare, new Date()) + defaultMedicare,
    }),
    generateDeduction({
        description: "State Tax",
        type: DeductionType.StateTax,
        amount: 0,
        ytd: 0,
    }),
]

const defaultNetPay = calculateNetPay(defaultTotal, initialDeductions)

const initialPayStubs = [
    generatePayStub(
        {
            currentDate: new Date(),
            currentPeriodRange: paymentScheduleInfo.Weekly.periodRange,
            earnings: initialEarnings,
            deductions: initialDeductions,
            grossPay: defaultTotal,
            grossYtd: calculateInitialYtd(PaySchedule.Weekly, defaultTotal, new Date()) + defaultTotal,
            netCheck: 0,
            netPay: defaultNetPay,
            check: -defaultNetPay,
            enableManualTax: false
        }
    )
]

export const initialPaystubsState: IPaystubData = {
    company: {
        name: "",
        city: "",
        address: "",
        email: "",
        zipcode: "",
        state: "",
    },
    employee: {
        name: "",
        city: "",
        address: "",
        zipcode: "",
        taxState: "",
        ssn: "",
        maritalStatus: MaritalStatus.Single,
        eid: "",
        isContractor: false,
        isNewHire: false,
        hireDate: formatDate(currentYearFirstDay(), "yyyy-MM-dd")

    },
    info: initialInfo,
    payStubs: initialPayStubs,
    accept: false,
    agree: false,
    downloaded: false,
}

const payStubSlice = createSlice({
    name: "paystub",
    initialState: initialPaystubsState,
    reducers: {
        loadPaystub: (state, action: PayloadAction<IPaystubData>) => {
            return action.payload
        },
        setPaymentMode: updatePaymentMode,
        setPaySchedule: (state, action: PayloadAction<PaySchedule>) => {

            const paySchedule = action.payload
            state.info.paySchedule = paySchedule
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate } = info

            const payPeriods = getPayPeriods(paySchedule)
            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                hireDate
            })
        },
        setHourlyRate: (state, action: PayloadAction<number>) => {
            state.info.hourlyRate = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)
            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                hireDate
            })
        },

        setAnnualSalary: (state, action: PayloadAction<number | undefined>) => {
            state.info.salary = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)
            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                hireDate
            })
        },

        setContractor: (state, action: PayloadAction<boolean>) => {
            state.employee.isContractor = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)
            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                hireDate
            })
        },
        setMaritalStatus: (state, action: PayloadAction<MaritalStatus>) => {
            state.employee.maritalStatus = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)
            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                hireDate
            })
        },
        setHireDate: (state, action: PayloadAction<string>) => {
            state.employee.hireDate = action.payload

            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                hireDate
            })
        },
        setPaystubCount: (state, action: PayloadAction<number>) => {
            const currentCount = action.payload
            state.info.payStubCount = currentCount
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            resizePaystubs(currentCount, state)

            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, hireDate
            })
        },
        setPayStubStartDate: (state, action: PayloadAction<{ date: string | Date, stubIndex: number }>) => {
            const { date, stubIndex } = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)
            const difference = paymentScheduleInfo[paySchedule].periodRange
            const initialEndDate = addDays(date, difference * (stubIndex + 1) - 1)
            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                initialEndDate,
                hireDate
            })
        },
        setPayStubEndDate: (state, action: PayloadAction<{ date: string | Date, stubIndex: number }>) => {
            const { date, stubIndex } = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)
            const difference = paymentScheduleInfo[paySchedule].periodRange
            const initialDate = addDays(date, difference * (stubIndex))
            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                initialEndDate: initialDate,
                hireDate
            })
        },
        setPayStubPayDate: (state, action: PayloadAction<{ date: string, stubIndex: number }>) => {
            const { payStubs } = state;
            const { date, stubIndex } = action.payload

            payStubs[stubIndex].payDate = date
        },
        setPayStubHours: (state, action: PayloadAction<{ hours: number, stubIndex: number }>) => {
            const { hours, stubIndex } = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)
            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, stubHours: { hours, index: stubIndex }, hireDate
            })
        },
        addAdditionalEarning: (state) => {
            const { payStubs } = state;

            payStubs.forEach((payStub) => {
                payStub.earnings.push(generateEarning({
                    total: 0, type: PaymentMode.Hourly, ytd: 0,
                    description: ""
                }))
            })
        },
        editAdditionalEarningDescription: (state, action: PayloadAction<{
            description: string,
            earningIndex: number
        }>) => {
            const { description, earningIndex } = action.payload
            const { payStubs, } = state;

            payStubs.forEach((payStub) => {
                payStub.earnings[earningIndex].description = description
            })
        },
        setFederalTax: (state, action: PayloadAction<{
            amount: number,
            payStubIndex: number
        }>) => {
            const { amount, payStubIndex } = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            payStubs[payStubIndex].deductions.forEach((deduction) => {
                if (deduction.type === DeductionType.FederalTax) {
                    deduction.amount = toDecimalPlaces(amount, 2)
                }
            })

            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                hireDate
            })
        },

        setStateTax: (state, action: PayloadAction<{
            amount: number,
            payStubIndex: number
        }>) => {
            const { amount, payStubIndex } = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            payStubs[payStubIndex].deductions.filter(deduction => deduction.type === DeductionType.StateTax)[0].amount = toDecimalPlaces(amount, 2);

            modifyPayStubs({
                payStubs,
                isContractor,
                maritalStatus,
                paymentMode,
                payPeriods,
                paySchedule,
                taxState,
                salary,
                hourlyRate,
                hireDate
            })
        },

        setEnableManualTax: (state, action: PayloadAction<{ setFederalStateTaxManually: boolean, payStubIndex: number }>) => {
            const { setFederalStateTaxManually, payStubIndex } = action.payload;
            const { payStubs, } = state;

            payStubs[payStubIndex].enableManualTax = setFederalStateTaxManually
        },

        setAdditionalEarningsRate: (state, action: PayloadAction<{ rate: number, earningIndex: number, stubIndex: number }>) => {
            const { rate, earningIndex, stubIndex } = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            if (stubIndex === 0) {
                payStubs.forEach((payStub) => {
                    const hours = payStub.earnings[earningIndex].hours
                    payStub.earnings[earningIndex].rate = rate
                    payStub.earnings[earningIndex].total = rate * (hours ?? 0)
                })
            } else {
                const hours = payStubs[stubIndex].earnings[earningIndex].hours
                payStubs[stubIndex].earnings[earningIndex].rate = rate
                payStubs[stubIndex].earnings[earningIndex].total = rate * (hours ?? 0)
            }

            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, hireDate
            })
        },

        setEarningsHours: (state, action: PayloadAction<{ hours: number, earningIndex: number, stubIndex: number }>) => {
            const { hours, earningIndex, stubIndex } = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            const rate = payStubs[stubIndex].earnings[earningIndex].rate
            payStubs[stubIndex].earnings[earningIndex].hours = hours
            payStubs[stubIndex].earnings[earningIndex].total = (rate ?? 0) * hours

            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, hireDate
            })
        },
        deleteAdditionEarning: (state, action: PayloadAction<number>) => {
            const earningIndex = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            payStubs.forEach((payStub) => {
                const earnings = payStub.earnings
                payStub.earnings = earnings.filter((_, index) => {
                    return index !== earningIndex
                })
            })

            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, hireDate
            })
        },
        deleteAdditionalEarnings: (state,) => {
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            payStubs.forEach((payStub) => {
                const earnings = payStub.earnings
                payStub.earnings = earnings.slice(0, 1)
            })
            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, hireDate
            })
        },
        addAdditionalDeduction: (state) => {
            const { payStubs } = state;

            payStubs.forEach((payStub) => {
                payStub.deductions.push(generateDeduction({
                    amount: 0, type: DeductionType.AdditionalDeduction, ytd: 0,
                    description: ""
                }))
            })
        },
        updateDeductionDescription: (state, action: PayloadAction<{
            description: string,
            deductionIndex: number
        }>) => {
            const { description, deductionIndex } = action.payload
            const { payStubs, } = state;

            payStubs.forEach((payStub) => {
                payStub.deductions[deductionIndex].description = description
            })
        },
        setDeductionAmount: (state, action: PayloadAction<{ amount: number, deductionIndex: number, stubIndex: number }>) => {
            const { amount, deductionIndex, stubIndex } = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            payStubs[stubIndex].deductions[deductionIndex].amount = amount

            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, hireDate
            })
        },
        deleteDeduction: (state, action: PayloadAction<number>) => {
            const deductionIndex = action.payload
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            payStubs.forEach((payStub) => {
                const deductions = payStub.deductions
                payStub.deductions = deductions.filter((_, index) => {
                    return index !== deductionIndex
                })

            })

            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, hireDate
            })
        },
        deleteDeductions: (state,) => {
            const { payStubs, info, employee } = state;
            const { isContractor, maritalStatus, taxState, hireDate } = employee
            const { paymentMode, salary, hourlyRate, paySchedule } = info
            const payPeriods = getPayPeriods(paySchedule)

            payStubs.forEach((payStub) => {
                const deductions = payStub.deductions
                payStub.deductions = deductions.slice(0, 4)
            })

            modifyPayStubs({
                payStubs, isContractor, maritalStatus, paymentMode,
                payPeriods, paySchedule, taxState, salary, hourlyRate, hireDate
            })
        },
        setStubsDownloaded: (state) => {
            state.downloaded = true
        },
        resetPayStubs: () => {
            return initialPaystubsState
        }
    }
})


export const selectPaystubData = (state: RootState) => state.payStub

export const {
    loadPaystub,
    setEarningsHours,
    addAdditionalEarning,
    setAdditionalEarningsRate,
    editAdditionalEarningDescription,
    setPaymentMode,
    setPaystubCount,
    setPayStubHours,
    setPaySchedule,
    setPayStubStartDate,
    setMaritalStatus,
    setHireDate,
    setHourlyRate,
    setAnnualSalary,
    setContractor,
    setPayStubPayDate,
    setPayStubEndDate,
    deleteAdditionalEarnings,
    deleteDeduction,
    deleteDeductions,
    addAdditionalDeduction,
    updateDeductionDescription,
    setDeductionAmount,
    deleteAdditionEarning,
    setFederalTax,
    setStateTax,
    setEnableManualTax,
    setStubsDownloaded,
    resetPayStubs
} = payStubSlice.actions
export default payStubSlice.reducer