import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { IDeduction } from "../../../../Types/Interface/IPaystub";
import { useAppDispatch } from "../../../../Hooks/useAppDispatch";
import {
    addAdditionalDeduction, deleteDeduction, deleteDeductions, setDeductionAmount, updateDeductionDescription
} from "../../../../Store/Slices/paystubSlice";
import trash from "../../../../Assets/Svg/trash.svg";
import { AddIcon } from "@chakra-ui/icons";

const CustomDeductions = ({ index, deductions }: { index: number, deductions: IDeduction[] }) => {

    const [canAddDeductions, setCanAddDeductions] = useState(deductions.length > 0)
    const dispatch = useAppDispatch()

    const addDeductions = () => {
        dispatch(addAdditionalDeduction())
        setCanAddDeductions(true)
    }

    const removeAllDeductions = () => {
        dispatch(deleteDeductions())
        setCanAddDeductions(false)
    }

    return (
        <>
            <Flex mt={index !== 0 ? "16px" : "0px"} align={"center"} justifyContent={"space-between"}>
                <Box>
                    {
                        (index === 0 || deductions.length !== 0) && (
                            <Text color={"#208c55"} fontSize={"18px"}>
                                Custom Deduction
                            </Text>
                        )
                    }
                    {
                        index === 0 && (
                            <Text color={"#0000007F"} fontSize={"18px"}>
                                Deduct insurance, 401k, other taxes etc.
                            </Text>
                        )
                    }
                </Box>
                {
                    index === 0 && (
                        <Checkbox isChecked={canAddDeductions} color={"#208c55"}
                            onChange={() => !canAddDeductions ? addDeductions() : removeAllDeductions()}
                            colorScheme={"green"} fontWeight={"normal"} borderColor={"#208c55"}
                            variant={"outline"}>
                            Add Deductions
                        </Checkbox>
                    )
                }
            </Flex>
            {
                deductions.map((deduction, deductionIndex) => (
                    <Flex key={deduction.id} gap={"8px"} mb={"16px"}>
                        <FormControl>
                            <FormLabel color={"#208c55"}>
                                Description
                            </FormLabel>
                            <Input value={deduction.description}
                                disabled={index !== 0}
                                onChange={(e) => {
                                    if (index === 0) {
                                        dispatch(updateDeductionDescription({ description: e.target.value, deductionIndex: deductionIndex + 4 }))
                                    }
                                }}
                                name={`payStubs[${index}].earnings[${deductionIndex + 4}].description`}
                                type={"text"} />
                        </FormControl>
                        <FormControl>
                            <FormLabel color={"#208c55"}>
                                Amount
                            </FormLabel>
                            <Input value={deduction.amount}
                                onChange={(e) => {
                                    const amount = e.target.value.length !== 0 ? parseFloat(e.target.value) : 0
                                    dispatch(setDeductionAmount({ amount, deductionIndex: deductionIndex + 4, stubIndex: index }))
                                }}
                                name={`payStubs[${index}].earnings[${deductionIndex + 4}].amount`}
                                type={"number"} />
                        </FormControl>
                        {
                            index === 0 && <Image cursor={'pointer'} boxSize={'40px'}
                                onClick={() => {
                                    dispatch(deleteDeduction(deductionIndex + 4))
                                    deductionIndex === 0 && setCanAddDeductions(false)
                                }} src={trash} alignSelf={'flex-end'} />
                        }
                    </Flex>
                ))
            }
            {
                index === 0 && canAddDeductions && (
                    <Flex justifyContent={"end"} mb={"8px"}>
                        <Button
                            onClick={() => addDeductions()}
                            _hover={{ bgColor: 'none' }}
                            textAlign={'center'}
                            bgColor={'#20E75B'}
                        >
                            <AddIcon h={'30px'} w={'30px'} p={1} borderRadius={'50%'} border={'2px solid #fff'} color={'#fff'} boxSize={'25px'} />
                        </Button>
                    </Flex>
                )
            }
        </>
    )
}

export default CustomDeductions