import { Box, Flex } from "@chakra-ui/react";
import FormikInput from "../Formik/FormikInput";
import BusinessFormsHeading from "./BusinessFormsHeading";
import { ChangeEvent, useState } from "react";
import { useFormikContext } from "formik";

const BusinessTaxDeduction = () => {

    const { values, submitForm, setFieldValue } = useFormikContext();

    return (

        <Box mb={"32px"}>
            <BusinessFormsHeading title={"Add Deduction"} />
            <Flex alignItems={"center"} gap={"20px"} justifyContent={"space-between"}>
                <FormikInput flexBasis={"40%"} label={"Tax %"} name={"taxAmount"} type={"number"} />
                <FormikInput flexBasis={"20%"} label={"Discount %"} name={"discountPercentage"} type={"number"} />
                <FormikInput flexBasis={"20%"} label={"Discount Amount"} name={"discountAmount"} type={"number"} />
            </Flex>
        </Box>
    )
}

export default BusinessTaxDeduction