import {
    DeductionType,
    MaritalStatus,
    PaymentMode,
    paymentScheduleInfo,
    PaySchedule
} from "../Types/Enums/PaymentModeEnum";
import {v4 as uuidv4} from "uuid";
import {IDeduction, IEarning, IPayStub, IPaystubData} from "../Types/Interface/IPaystub";
import {
    calculateMedicareTax,
    calculateSocialSecurity,
    calculateStatetax,
    calculateYTD,
    getTaxPercentageByStatus
} from "./utils";

import { addDays, differenceInBusinessDays, differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarQuarters, differenceInCalendarWeeks, differenceInMonths, differenceInQuarters, differenceInYears, formatDate, startOfYear } from "date-fns";
import EmployeeInfo from "Pages/W2/Components/Forms/EmployeeInfo";
import { getStartOfYear } from "react-datepicker/dist/date_utils";

export const generateDeduction = ({ description, type, amount, ytd }: {
    description: string,
    type: DeductionType,
    amount?: number,
    ytd: number
}) => {
    return {
        id: uuidv4(),
        description,
        type,
        amount,
        ytd,
    }
}

export const generateEarning = ({ description, rate, ytd, total, hours, type }: {
    description: string,
    rate?: number,
    type: PaymentMode,
    hours?: number,
    total: number,
    ytd: number
}): IEarning => {
    return {
        id: uuidv4(),
        description,
        rate,
        type,
        hours,
        total,
        ytd
    }
}

export const getFromDate = (currentDate: string | Date, currentPeriodRange: number) => formatDate(addDays(currentDate, -currentPeriodRange + 1), "yyyy-MM-dd")

export const generatePayStub = (
    { currentPeriodRange, currentDate, earnings, deductions, grossPay, grossYtd, netCheck, netPay, check }:
        {
            currentDate: string | Date, earnings: IEarning[],
            deductions: IDeduction[], currentPeriodRange: number,
            grossPay: number, grossYtd: number, netCheck: number, netPay: number, check: number, enableManualTax: boolean
        }): IPayStub => {

    return {
        id: uuidv4(),
        from: getFromDate(currentDate, currentPeriodRange),
        to: formatDate(currentDate, "yyyy-MM-dd"),
        payDate: formatDate(currentDate, "yyyy-MM-dd"),
        earnings, deductions,
        grossPay: {
            amount: grossPay,
            ytd: grossYtd
        },
        netCheck,
        netPay,
        check,
        enableManualTax: false
    }
}

export const calculateCurrentGrossPay = (earnings: IEarning[]): number => {
    return earnings.reduce((acc, current) => acc + current.total, 0)
}

export const calculateCurrentGrossYtd = (earnings: IEarning[]): number => {
    return earnings.reduce((acc, current) => acc + current.ytd, 0)
}

export const calculateEarningsYtd = (prevYtd: number, earning: number) => {
    return prevYtd + earning
}

export const calculateEstimatedAnnualGross = (grossPay: number, currentPayPeriods: number) => {
    return grossPay * currentPayPeriods
}

export const getPayPeriods = (paySchedule: PaySchedule) => paymentScheduleInfo[paySchedule].payPeriods()
export const getPayHours = (paySchedule: PaySchedule) => paymentScheduleInfo[paySchedule].hours
export const getCurrentPeriod = (paySchedule: PaySchedule, initialPayStubEnd: string | Date, startDate: Date | undefined) => paymentScheduleInfo[paySchedule].getCurrentPayPeriod(initialPayStubEnd, startDate)

export const getFIT = (grossPay: number, paySchedule: PaySchedule, status: MaritalStatus) => {
    const payPeriods = getPayPeriods(paySchedule)
    const annualSalary = calculateEstimatedAnnualGross(grossPay, payPeriods)
    return annualSalary * getTaxPercentageByStatus(annualSalary, status)
}

export const getStateTax = (grossPay: number, paySchedule: PaySchedule, status: MaritalStatus, stateCode: string) => {
    const payPeriods = getPayPeriods(paySchedule)
    const annualSalary = calculateEstimatedAnnualGross(grossPay, payPeriods)
    return calculateStatetax(stateCode, status === MaritalStatus.Married, annualSalary)
}

export const getSocialSecurity = (grossPay: number, paySchedule: PaySchedule) => {
    const payPeriods = getPayPeriods(paySchedule)
    const annualSalary = calculateEstimatedAnnualGross(grossPay, payPeriods)
    return calculateSocialSecurity(annualSalary)
}
export const getMedicare = (grossPay: number, paySchedule: PaySchedule) => {
    const payPeriods = getPayPeriods(paySchedule)
    const annualSalary = calculateEstimatedAnnualGross(grossPay, payPeriods)
    return calculateMedicareTax(annualSalary)
}

export const calculateInitialYtd = (paySchedule: PaySchedule, defaultAmount: number, startDate: string | Date) => {
    const prevPayStubPeriod = paymentScheduleInfo[paySchedule].getCurrentPayPeriod(startDate) - 1
    return calculateYTD(defaultAmount, prevPayStubPeriod)
}

export const calculateNetPay = (grossPay: number, deductions: IDeduction[]) => {
    return deductions.reduce((acc, current) => acc - (current.amount ?? 0), grossPay)
}

export const calculateYTDNew = (grossPay: number, startDate: Date, endDate: Date, payPeriod: Number, paySchedule: PaySchedule) => {
    let period = 0;
    if (paySchedule == PaySchedule.Annually) {
        period = differenceInYears(endDate, startDate)
    }
    else if(paySchedule == PaySchedule.SemiAnnually)
    {   period = Math.floor(differenceInCalendarQuarters(endDate, startDate) / 2)
    }
    else if (paySchedule == PaySchedule.Quarterly) {
        period = differenceInCalendarQuarters(endDate, startDate)
    }
    else if (paySchedule == PaySchedule.Monthly) {
        period = differenceInCalendarMonths(endDate, startDate)
    }
    else if (paySchedule == PaySchedule.Weekly) {
        period = differenceInCalendarWeeks(endDate, startDate)
    }
    // We need to fix this....
    else if (paySchedule == PaySchedule.SemiMonthly) {
        period = differenceInCalendarMonths(endDate, startDate) * 2
    }
    else if (paySchedule == PaySchedule.BiWeekly) {
        period = Math.floor(differenceInCalendarWeeks(endDate, startDate, {}) / 2)

    }
}

export const modifyPayStubs = ({
    payStubs,
    paySchedule,
    stubHours,
    payPeriods,
    maritalStatus,
    paymentMode,
    salary,
    hourlyRate,
    taxState,
    initialEndDate,
    isContractor,
    hireDate
}: {
    payStubs: IPayStub[],
    isContractor: boolean,
    maritalStatus: MaritalStatus,
    paymentMode: PaymentMode,
    payPeriods: number,
    paySchedule: PaySchedule,
    taxState: string,
    stubHours?: { hours: number, index: number }
    salary?: number,
    hourlyRate?: number,
    initialEndDate?: string | Date,
    hireDate?: string
}) => {
    // creates a shallow copy of the paystubs
    const reversedStubs = [...payStubs].reverse()
    const defaultAnnualSalary = 48000
    const defaultHourlyRate = 20
    const lastPaystubIndex = reversedStubs.length - 1
    const initialDate = initialEndDate ?? payStubs[0].to
    const difference = paymentScheduleInfo[paySchedule].periodRange


    let initialEarningYtd = 0
    let initialFitYtd = 0
    let initialSocialYtd = 0
    let initialMedicareYtd = 0
    let initialStateYtd = 0
    const additionalDetailsYtd: { [k: number]: number } = {}

    /** todo the problem is from here about the hired date
     * the paystubs are reversed so the ytd would be reversed based of the hiredate
     * need to find a way to calculate the ytd without the reversal
     **/
    reversedStubs.forEach((paystub, index) => {
        const earning = paystub.earnings[0]
        const currentIndex = lastPaystubIndex - index
        paystub.to = formatDate(addDays(initialDate, - difference * currentIndex), "yyyy-MM-dd")
        paystub.from = formatDate(addDays(initialDate, - difference * (currentIndex + 1) + 1), "yyyy-MM-dd")
        paystub.payDate = formatDate(addDays(paystub.to, 1), "yyyy-MM-dd")
        const ytdStartDate = (hireDate === "" || hireDate === undefined) ? formatDate(startOfYear(new Date()), "yyyy-MM-dd") : formatDate(hireDate, "yyyy-MM-dd")

        console.log("ytdStartDate..........", ytdStartDate)
        if (paymentMode === PaymentMode.Salary) {
            earning.description = "Salary"
            earning.total = (salary ?? defaultAnnualSalary) / payPeriods
        } else {
            earning.description = "Regular"
            earning.rate = hourlyRate
            earning.hours = stubHours !== undefined && currentIndex === stubHours.index ? stubHours.hours : getPayHours(paySchedule)
            earning.total = (hourlyRate ?? defaultHourlyRate) * earning.hours
        }
        // calculate ytds
        if (index === 0) {
            initialEarningYtd = calculateInitialYtd(paySchedule, earning.total, ytdStartDate) + earning.total
        } else {
            initialEarningYtd += earning.total
        }
        earning.ytd = initialEarningYtd
        const grossPay = paystub.earnings.reduce((acc, current) => acc + current.total, 0)
        Object.assign(
            paystub.grossPay,
            {
                amount: grossPay,
                ytd: paystub.earnings.reduce((acc, current) => acc + current.ytd, 0),
            })
        let netPay = grossPay
        paystub.deductions.forEach((deduction, deductionIndex) => {
            let deductionAmount: number

            if (isContractor) {
                deductionAmount = 0
            }
            else if (deduction.type === DeductionType.FederalTax) {
                if (deduction.amount === undefined) {
                    deductionAmount = 0
                }
                else {
                    deductionAmount = !paystub.enableManualTax ? getFIT(grossPay, paySchedule, maritalStatus) / payPeriods : deduction.amount
                }
                initialFitYtd = index === 0 ? calculateInitialYtd(paySchedule, deductionAmount, ytdStartDate) + deductionAmount : initialFitYtd + deductionAmount
                deduction.ytd = initialFitYtd
            } else if (deduction.type === DeductionType.Medicare) {
                deductionAmount = getMedicare(grossPay, paySchedule) / payPeriods
                initialMedicareYtd = index === 0 ? calculateInitialYtd(paySchedule, deductionAmount, ytdStartDate) + deductionAmount : initialMedicareYtd + deductionAmount
                deduction.ytd = initialMedicareYtd
            } else if (deduction.type === DeductionType.SocialSecurity) {
                deductionAmount = getSocialSecurity(grossPay, paySchedule) / payPeriods
                initialSocialYtd = index === 0 ? calculateInitialYtd(paySchedule, deductionAmount, ytdStartDate) + deductionAmount : initialSocialYtd + deductionAmount
                deduction.ytd = initialSocialYtd
            } else if (deduction.type === DeductionType.StateTax) {
                if (deduction.amount === undefined) {
                    deductionAmount = 0
                }
                else {
                    deductionAmount = !paystub.enableManualTax ? getStateTax(grossPay, paySchedule, maritalStatus, taxState) : deduction.amount
                }
                initialStateYtd = index === 0 ? calculateInitialYtd(paySchedule, deductionAmount, ytdStartDate) + deductionAmount : initialStateYtd + deductionAmount
                deduction.ytd = initialStateYtd
            } else {
                if (deduction.amount === undefined) {
                    deductionAmount = 0
                }
                else {
                    deductionAmount = deduction.amount
                }
                deduction.ytd = additionalDetailsYtd[deductionIndex] ? additionalDetailsYtd[deductionIndex] + deductionAmount : deductionAmount
                if (index === 0) {
                    additionalDetailsYtd[deductionIndex] = deductionAmount
                }
            }
            deduction.amount = deductionAmount
            netPay -= deductionAmount
        })

        paystub.netPay = netPay
        paystub.check = -netPay
        paystub.netCheck = 0
    })
}

export const resizePaystubs = (currentCount: number, data: IPaystubData) => {
    const { payStubs } = data
    const prevCount = payStubs.length
    let prevStub = payStubs[prevCount - 1]
    const defaultEarnings = payStubs[0].earnings
    const defaultDeductions = payStubs[0].deductions
    const initialDate = payStubs[0].to
    let prevEarningYtd = prevStub.earnings[0].ytd

    if (prevCount > currentCount) {
        data.payStubs = [...payStubs.slice(0, currentCount)]
    } else {
        const currentPeriodRange = paymentScheduleInfo[data.info.paySchedule].periodRange
        const earnings = defaultEarnings.map((earning, index) => {
            prevEarningYtd -= earning.total
            return generateEarning({
                ...earning,
                hours: index === 0 ? earning.hours : undefined,
                total: index === 0 ? earning.total : 0,
                ytd: index === 0 ? prevEarningYtd : 0
            })
        })
        const deductions = defaultDeductions.map((deduction, index) => {
            return generateDeduction({
                ...deduction,
                amount: index < 4 ? deduction.amount ?? 0 : 0,
            })
        })

        const grossPay = earnings.reduce((acc, current) => acc + current.total, 0)
        const netPay = calculateNetPay(grossPay, deductions)

        const newPaystubs = Array.from({ length: currentCount - prevCount },
            (_, index) => {
                const toDate = addDays(initialDate, -currentPeriodRange * (prevCount + index))

                const currentStub = generatePayStub({
                    check: -netPay,
                    currentPeriodRange,
                    deductions,
                    earnings,
                    grossPay,
                    grossYtd: earnings.reduce((acc, current) => acc + current.ytd, 0),
                    netCheck: 0,
                    netPay,
                    currentDate: toDate,
                    enableManualTax: false
                })
                prevStub = currentStub
                return currentStub
            }
        )
        Object.assign(payStubs, [...payStubs, ...newPaystubs])
    }
}
