import { Button, Flex, Link, Image } from '@chakra-ui/react';
import React from 'react';
import { AppRoutes } from 'Routes/AppRoutes';
import { Link as RLink, useNavigate } from 'react-router-dom';
import Logo from 'Assets/Svg/Logo.svg';

const Navbarlinks: { label: string; path: string }[] = [
	{
		label: 'Home',
		path: AppRoutes.home,
	},
	{
		label: 'Paystub',
		path: '/paystub',
	},
	{
		label: 'Invoice',
		path: '',
	},
	{
		label: 'Quote',
		path: '/quotes',
	},
	{
		label: 'Receipt',
		path: '/receipt',
	},
	{
		label: 'W2',
		path: '',
	},
	{
		label: '1099',
		path: '',
	},
	{
		label: 'Pricing',
		path: '/pricing'
	}
];

export const Navbar = () => {
	const navigate = useNavigate();
	return (
		<Flex bg={'white'} p={'6'} w={'full'} justifyContent={'space-between'} alignItems={'center'}>
			<Image src={Logo} />
			<Flex w={'full'} justifyContent={'center'} alignItems={'center'}>
				{Navbarlinks.map((link) => {
					return (
						<Link cursor={'pointer'} as={RLink} to={link.path} key={link.label} color={'Green.100'} pl={'8'}>
							{link.label}
						</Link>
					);
				})}
			</Flex>
			<Flex >
				<Button w={'88px'} h={'43px'} mr={'22px'} variant={'primary'} onClick={() => navigate(AppRoutes.login)}>
					Login
				</Button>
				<Button w={'88px'} h={'43px'} variant={'secondary'} onClick={() => navigate(AppRoutes.signUp)}>
					Sign up
				</Button>
			</Flex>
		</Flex>
	);
};
