import React from "react";
import {Box, Text, Flex, InputGroup, Input, InputRightElement, Button} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";
import {AppRoutes} from "../Routes/AppRoutes";


type TNavLink = {
    description: string,
    notLink?: boolean,
    link: string,
}

const AppFooterV2 = () => {
  return (
      <footer style={{background: "#00645A", boxShadow: ""}}>
          <Box px={{base: "16px", md: "34px"}} py={"24px"}>
              <Box display={{ md: "none"}}>
                  <NewsLetter/>
              </Box>
              <Flex mx={{base: "12px", md: "50px"}} gap={"52px"}>
                  <FooterLinkBox
                      width={"201px"}
                      navLinks={[
                          {
                              description: "About Us",
                              link: "#"
                          },
                          {
                              description: "Pricing",
                              link: AppRoutes.pricing
                          },
                          {
                              description: "Blog",
                              link: "#"
                          },
                          {
                              description: "FaQs",
                              link: "#"
                          }
                      ]}
                      title={"Company"}
                      />
                  <FooterLinkBox
                      width={"168px"}
                      navLinks={[
                          {
                              description: "Whatsapp",
                              link: "#"
                          },
                          {
                              description: "Twitter",
                              link: "#"
                          },
                      ]}
                      title={"Support / Live Chat"}
                      />
                  <FooterLinkBox
                      width={"280px"}
                      navLinks={[
                          {
                              description: "support@phcworkhub.com",
                              link: "mailto:support@phcworkhub.com"
                          },
                          {
                              notLink: true,
                              description: "3255 Lawrenceville Suwanee Rd \n" +
                                  "Ste P PMB 630, \n" +
                                  "Suwanee, GA 30024.",
                              link: "#"
                          },
                      ]}
                      title={"Contact Us"}
                      />
                  <Box display={{base: "none", lg: "block"}}>
                      <NewsLetter/>
                  </Box>
              </Flex>
              <Flex mx={{base: "12px", md: "50px"}} gap={"52px"}>
                  <FooterLinkBox
                      width={"201px"}
                      navLinks={[
                          {
                              description: "Whatsapp",
                              link: "#"
                          },
                          {
                              description: "Twitter",
                              link: "#"
                          },
                          {
                              description: "TikTok",
                              link: "#"
                          },
                          {
                              description: "LinkedIn",
                              link: "#"
                          },
                          {
                              description: "Instagram",
                              link: "#"
                          }
                      ]}
                      title={"Connect with PhcWorkHub"}
                  />
                  <FooterLinkBox
                      width={"168px"}
                      navLinks={[
                          {
                              description: "Home",
                              link: "/"
                          },
                          {
                              description: "Paystub",
                              link: AppRoutes.payStub
                          },
                          {
                              description: "W2",
                              link: AppRoutes.w2
                          },
                          {
                              description: "1099",
                              link: AppRoutes.f1099Misc
                          },
                          {
                              description: "Real Estate",
                              link: AppRoutes.realEstate
                          },
                          {
                              description: "Business",
                              link: "#"
                          },
                      ]}
                      title={"Explore"}
                  />
                  <Box display={{base: "none", md: "block", lg: "none"}}>
                      <NewsLetter/>
                  </Box>
              </Flex>
              <Flex justifyContent={"space-between"} color={"white"} borderTopWidth={"1px"} borderColor={"white"} pt={"14px"} fontSize={{base: "10px", md: "16px"}}>
                  <Flex>
                      <Text mr={"8px"} fontWeight={"light"}>
                          Copyright &copy; {new Date().getFullYear()}
                      </Text>
                      <Text color={"#1fbc6c"} fontWeight={"light"}>
                          <NavLink to={"/"}>
                              phcworkhub.com
                          </NavLink>
                      </Text>
                  </Flex>
                  <Text fontWeight={"light"}>
                      All Rights Reserved.
                  </Text>
                  <Flex gap={"8px"}>
                      <NavLink to={"/#"}>
                          <Text fontWeight={"light"}>
                              Privacy Policy.
                          </Text>
                      </NavLink>
                      <NavLink to={"/#"}>
                          <Text fontWeight={"light"}>
                              Terms and Conditions.
                          </Text>
                      </NavLink>
                  </Flex>
              </Flex>
          </Box>
      </footer>
  )
}

const FooterLinkBox = ({navLinks, title, width}: {navLinks: TNavLink[], title: string, width?: string}) => {
    return (
        <Box color={"white"} maxW={width}>
            <Box py={"10px"} borderBottomWidth={"0.5px"} borderColor={"white"} mb={"7px"} width={"100%"}>
                <Text fontSize={"16px"}>
                    {title}
                </Text>
            </Box>
            {
                navLinks.map(navLink => (
                    <Text key={navLink.description} maxW={"290px"} fontSize={"14px"} fontWeight={"light"} mb={"7px"}>
                        {navLink.notLink ? navLink.description : <NavLink to={navLink.link}>{navLink.description}</NavLink>}
                    </Text>
                ))
            }
        </Box>
    )
}

const NewsLetter = () => {
    return (
        <Box>
            <Box py={"10px"} borderBottomWidth={"0.5px"} borderColor={"white"} mb={"7px"} maxW={"400px"}>
                <Text fontSize={"16px"} color={"white"}>
                    Sign-up to our newsletter to receive updates, invitations to our events and access to special promotions.
                </Text>
            </Box>
            <InputGroup size='md'>
                <Input
                    colorScheme={"green"}
                    pr='4.5rem'
                    type={'email'}
                    placeholder='Enter Email'
                />
                <InputRightElement width='4.5rem'>
                    <Button colorScheme={"green"} borderLeftRadius={"0px"} borderWidth={"1px"} height={"100%"} borderLeftWidth={"0px"} borderColor={"transparent"} onClick={()=> {}}>
                        Sign up
                    </Button>
                </InputRightElement>
            </InputGroup>
        </Box>
    )
}

export default AppFooterV2