import ReceiptDocument from "../../documents/ReceiptDocument";
import React, {ReactNode} from "react";
import QuoteDocument from "../../documents/QuoteDocument";
import PaystubDocument from "../../documents/PaystubDocument";
import W2Document from "../../documents/W2Document";
import F1099MiscDocument from "../../documents/F1099MiscDocument";
import F1099NecDocument from "../../documents/F1099NecDocument";
import {IDocumentCheckout} from "../Interface/IRequestBody";

export enum documentTypeEnums {
    default = 0,
    Quotes = 1,
    Receipt = 2,
    Paystub = 3,
    W2 = 4,
    F1099 = 5,
}

export enum FormType {
    Quotes = "Quotes",
    Receipt = "Receipt",
    Paystub = "Paystub",
    W2 = "W2",
    F1099 = "F 1099 Misc",
    F1099Nec = "F 1099 Nec",
}

type DocMap = {
    [k in FormType]: ReactNode
}

export const DocumentMap = ({hasPaid, formType, session}: {hasPaid?: boolean, formType: FormType, session?: IDocumentCheckout}) => {
    const docs: DocMap  = {
        [FormType.Receipt]: <ReceiptDocument hasPaid={hasPaid} session={session}/>,
        [FormType.Quotes]: <QuoteDocument hasPaid={hasPaid} session={session}/> ,
        [FormType.Paystub]: <PaystubDocument hasPaid={hasPaid} session={session}/>,
        [FormType.F1099]: <F1099MiscDocument hasPaid={hasPaid} session={session}/> ,
        [FormType.F1099Nec]: <F1099NecDocument hasPaid={hasPaid} session={session}/> ,
        [FormType.W2]: <W2Document hasPaid={hasPaid} session={session}/>
    }

    return docs[formType]
}
