import {useAppSelector} from "../Hooks/useAppSelector";
import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Center} from "@chakra-ui/react";
import PdfViewer from "../Pages/Components/PdfViewer";
import useFill1099Nec, {transformNecData} from "../Hooks/useFill1099Nec";
import {select1099NecState, setNecDownloaded} from "../Store/Slices/1099NecSlice";
import {IDocumentCheckout} from "../Types/Interface/IRequestBody";
import {reqClient} from "../Api/reqClient";
import {I1099Nec} from "../Pages/1099Nec/validator";
import {useAppDispatch} from "../Hooks/useAppDispatch";

const checkOutNec = async (session: IDocumentCheckout, data: I1099Nec)=>{
    const {checkoutMode, sessionId, amount} = session
    const url = `/_1099/${sessionId}/${amount}/${checkoutMode}`
    const transformedData = transformNecData(data)
    const body = {
        "_1099Dto": {
            ...transformedData._1099Dto,
            payer: {...transformedData._1099Dto.payer, isCompletedForm: true},
            recipient: {
                ...transformedData._1099Dto.recipient, isCompletedForm: true
            },
            _1099NECOptions: {
                ...transformedData._1099Dto._1099NECOptions, isCompletedForm: true
            }
        }
    }

    const response = await reqClient.post(url, body)
    const resData = response.data
    return resData.isSuccess ?? false
}

const F1099Document = ({hasPaid, session}: {hasPaid?: boolean, session?: IDocumentCheckout}) => {
    const data = useAppSelector(select1099NecState)
    const {data: pdfData, refreshData, loading} = useFill1099Nec(data)
    const anchorRef = useRef<HTMLAnchorElement>(null)
    const [checkoutLoading, setCheckOutLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(()=>{
        if (hasPaid && session){
            setCheckOutLoading(true);
            checkOutNec(session, data).then((res)=>{
                setIsSuccess(res)
            }).catch(()=>{

            }).finally(()=>{
                setCheckOutLoading(false)
            })
        }
    }, [session, hasPaid])

    const downloadPdf = () => {
        const pdfUrl = URL.createObjectURL(pdfData as Blob)
        if (anchorRef.current){
            anchorRef.current.href = pdfUrl
            anchorRef.current.download = "1099 Nec"
            anchorRef.current.click()
        }
        dispatch(setNecDownloaded())
    }

    if (hasPaid && session) {
        return (
            <>
                {
                    checkoutLoading ? (
                        <Button>
                            Verifying payment...
                        </Button>
                    ) : (
                        isSuccess ? (
                            <>
                                <Box position="absolute" top="-9999px" left="-9999px">
                                    <a href={'#'} ref={anchorRef}>Download file</a>
                                </Box>
                                <Button onClick={downloadPdf}>
                                    Download 1099 Nec
                                </Button>
                            </>
                        ) : <Center>An error occurred, could not verify payment</Center>
                    )
                }
            </>
        )
    }

    return (
        <PdfViewer isPreview src={pdfData} documentLoading={loading} onRefresh={() => { refreshData() }}></PdfViewer>
    )
}

export default F1099Document