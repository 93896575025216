import * as React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/road-rage';
import '@fontsource/roboto';
import '@fontsource/lemon';
import '@fontsource/zen-kaku-gothic-antique';
import { theme } from 'Theme/BaseTheme';
import { MainRoute } from 'Routes/MainRoutes';
import { DeviceQueryWrapper } from "./Hooks/useDeviceQuery";

export const App = () => (
	<>
		<ChakraProvider theme={theme}>
			<DeviceQueryWrapper>
				<MainRoute />
			</DeviceQueryWrapper>
		</ChakraProvider>
	</>
);
